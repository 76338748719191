@font-face {
  font-family: 'arial-light';
  src: local('arial-light'), url('../assets/font/arial.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Arial-Bold';
  src: local('Arial-Bold'), url('../assets/font/arialbd.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Arial-Medium';
  src: local('Arial-Medium'),
    url('../assets/font/arialme.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Arial-Regular';
  src: local('Arial-Regular'),
    url('../assets/font/arial.ttf') format('truetype');
  font-weight: 400;
}

// .fixed-heading {
//   position: fixed;
//   background-color: #09171f;
//   width: 100%;
//   min-height: 85px;
//   z-index: 99;
//   margin-top: -12px !important;
//   margin-left: -77px !important;
//   padding: 0px 40px;
// }

// .body-content {
//   margin-top: 90px !important;
// }
.fc .fc-button {
  text-transform: capitalize;
}
.fc .fc-timegrid-axis-cushion {
  text-transform: capitalize;
}
.fc .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
}
.fc-event-title {
  text-overflow: ellipsis;
}
// .fc .fc-button-group {
//   button {
//     text-transform: capitalize;
//   }
// }

.strategy_secion_name {
  font-size: 15px;
  font-family: Arial-Bold !important;
  color: #dfdfdf;
}
.text-capitalize {
  text-transform: capitalize;
}

.w-100 {
  width: 100% !important;
}

.optionListContainer {
  z-index: 9999 !important;
}

.bottom-fixed {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #09171f;
  padding: 15px 20px;
}

thead {
  position: sticky;
  top: 0;
  background-color: #e9eaeb;
  z-index: 1;
}

// td,
th {
  padding: 0.6rem !important;
  text-transform: capitalize;
}

tbody {
  tr:hover {
    background-color: rgba(226, 242, 255, 0.9);
  }
}

.drop-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    180deg,
    rgb(0, 0, 0) -13%,
    rgb(74, 74, 74) 85%
  );
  box-shadow: 0px 3px 20px rgb(0 0 0 / 40%);
  border-radius: 18px;
  z-index: -1;
}

.cdrop {
  .MuiPaper-root {
    background-color: unset;
    border-radius: 18px;
    min-width: 215px;
  }

  .d-text {
    font-size: 15px !important;
    padding: 1rem 1rem;
    transition: 0.5s;
    color: #cccccc !important;
    cursor: pointer;

    &:hover {
      background-color: #002666;
    }
  }
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  border-radius: 4px;
}

::-webkit-scrollbar-track-piece {
  background: #ccc;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

​ :focus {
  outline: none !important;
}

#Upload_Now {
  font-family: 'Arial-Regular';
}

.pdf-hidden {
  display: none;
}

.text-overflow-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-viewer {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  // width: 100vh;
  // height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body,
div,
span,
label,
input,
textarea,
button,
select,
option,
td,
th,
li,
p {
  font-family: Arial-Regular !important;
}

.fileError {
  background-color: #f62a2a8f !important;
}

.tempI129 {
  section {
    display: flex;
    align-items: center;
  }

  .logo {
    max-width: 90px;
    max-height: 31%;
  }

  .card-title {
    font-weight: 600;
    font-size: 215%;
    // margin-top: 26px;
  }

  p {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 15px !important;
    margin: 0px 15px;
  }
}

.liberty-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: url('../assets/images/Liberty-loader.gif') 50% 50% no-repeat
    rgb(0 0 0 / 32%);
}

.liberty-loader-div {
  background-position: center !important;
  // background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url('../assets/images/Liberty-loader.gif');
  width: 100%;
  height: 100vh;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.active > li {
  background-color: #002c69 !important;

  &:hover {
    background-color: #002c69 !important;
  }
}

.switchToggle {
  margin-left: 12px;

  .MuiSwitch-switchBase {
    top: 8px !important;
  }
}

.main-container {
  position: relative;

  .setting-icon {
    position: fixed;
    display: block;
    bottom: 15%;
    left: -1px;
    z-index: 10000;
    cursor: pointer;
    transition: 0.5s;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .menu-arrow {
        text-align: right;
        float: right;
        font-size: 14px !important;
        color: lightgrey;
      }

      .m-li {
        font-size: 14px !important;
        padding: 0.712rem 1rem 0.712rem 2rem;
        border-bottom: 1px solid #002c69;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
          background-color: #002c69;
        }
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin: 2rem 0 1rem 0 !important;
    }

    .s-name {
      font-size: 18px !important;
      font-family: Arial-Bold !important;
      text-align: center;
    }

    .s-mail {
      font-size: 14px !important;
      margin: 0.5rem 0 1.5rem 0;
      opacity: 0.4;
      word-break: break-all !important;
    }

    // &:hover {
    //   left: 257px !important;

    //   .sidebar {
    //     left: 0 !important;
    //   }
    // }
  }

  .menubar {
    left: 257px !important;
  }

  .main-menubar {
    left: 0px !important;
  }

  .menubar-close {
    left: -0px !important;
  }

  .main-menubar-close {
    left: -260px !important;
  }

  .sidebar {
    transition: 0.5s;
    background-color: #191c1e;
    height: 100vh;
    overflow-y: auto;
    width: 258px;
    position: fixed;
    left: -258px;
    top: 0;
    z-index: 1001;

    .logout-box {
      //min-height: 18.31vh;
      padding-top: 1rem;

      .theme-box {
        min-height: 35px;
        border-radius: 5px;
        cursor: pointer;
        background-color: #add8e626;
        margin: 0.5rem 0;

        .active-theme {
          min-height: 35px;
          background-color: #3b90ed;
          border-radius: 5px;
        }
      }

      .l-btn {
        padding: 0.7rem 2rem;
        border-top: 1px solid #002c69;
        cursor: pointer;
        transition: 0.5s;
        font-size: 17px !important;

        svg {
          font-size: 1em !important;
          transform: rotate(180deg);
          margin-right: 0.5rem;
        }

        &:hover {
          background-color: #002c69;
        }
      }
    }

    .main-submenu {
      height: calc(100vh - 45vh);
      overflow-y: auto;

      a {
        color: #fff !important;
        text-decoration: unset;
      }

      .cl-box:nth-child(4) {
        a {
          .cl {
            &:hover {
              .submenu-list-show {
                max-height: calc(100vh - 62.13vh) !important;
              }
            }
          }
        }
      }

      .m-li {
        position: relative;

        .submenu-list-hide {
          display: none;
          transition: 0.5s;
        }

        .submenu-list-show {
          max-height: calc(100vh - 49.13vh);
          overflow-y: auto;
          display: block;
          position: fixed;
          z-index: 1001;
          width: 258px;
          background-color: #191c1e;
          left: 258px;
          margin-top: -1.9rem;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;

          a:last-child {
            li {
              border-bottom: unset !important;
            }
          }

          li {
            font-size: 14px !important;
            padding: 0.712rem 1rem 0.712rem 2rem;
            border-bottom: 1px solid #002c69;
            cursor: pointer;
            transition: 0.5s;

            &:hover {
              background-color: #002c69;
            }
          }
        }
      }
    }
  }

  .petitioner-menu {
    padding-bottom: 2rem;

    .nav-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .nav-item {
      font-size: 22px !important;
      font-family: Arial-Bold !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        line-height: 35px;
      }
    }

    .item-active {
      border-bottom: 3px solid #ffffff;

      a {
        text-decoration: unset !important;
        color: #ffffff !important;
      }
    }

    a.item-active {
      color: #ffffff;
    }

    a.item-inactive {
      color: #ffffff;
    }
  }

  .black-white-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #09171f;
    z-index: 100;
    min-height: 65px;
    padding: 0 1.5rem 0 1rem;

    .w-1366 {
      min-height: 80px;
      align-items: center;
      display: flex;
      // flex-wrap: wrap;
    }

    .nav-flex-1 {
      flex: 1;
    }

    .nav-flex-4 {
      flex: 2;
    }

    .nav-menu {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .nav-item {
      font-size: 22px !important;
      font-family: Arial-Bold !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 2rem;

      a {
        line-height: 35px;
      }
    }

    .item-active {
      border-bottom: 3px solid #ffffff;

      a {
        text-decoration: unset !important;
        color: #ffffff !important;
      }
    }

    a.item-active {
      color: #ffffff;
    }

    a.item-inactive {
      color: #ffffff;
    }

    .profile-box {
      position: relative;
      z-index: 10;
      width: 250px;
      height: 60px;
      border-radius: 18px;
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }

    .p-image {
      position: absolute;
      z-index: 4;
      top: 12px;
      right: 4px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #ffffff;
      border: 1px solid lightgray;
      color: #000;
      text-transform: uppercase;
      font-size: 18px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .white-text {
    color: #ffffff;
  }

  .total-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem !important;

    .total-item {
      min-width: 208px;
      // flex: 1;
      min-height: 85px;
      border-radius: 10px;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      padding: 10px 25px;
      display: flex;
      align-items: center;
      background: rgba(242, 242, 242, 15%);
    }

    // .t-inner-box {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   flex: 1;
    //   min-height: 85px;
    //   background-color: #f2f2f2;
    //   border-radius: 10px;
    //   opacity: 0.15;
    // }

    .t-title {
      color: #ffffff !important;
      flex: 1;
      line-height: 22px;
      font-size: 16px !important;
      letter-spacing: 0;
    }

    .t-count {
      font-size: 22px !important;
      text-align: right;
      flex: 1;
      font-family: Arial-Bold !important;
      color: #1abfc7 !important;
      letter-spacing: 0;
    }
  }

  .cp-box {
    display: flex;
    flex-wrap: wrap;

    .cp-flex-4 {
      flex: 3;
      padding-right: 1rem;
    }

    .cp-flex-1 {
      flex: 1;
    }

    .cp-item {
      min-height: 101px;
      position: relative;
      border-radius: 10px;
      padding: 10px 10px 10px 50px;
      margin-bottom: 10px;
      background: rgba(242, 242, 242, 0.15);
      justify-content: space-between;

      // .cp-inner {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   flex: 1;
      //   min-height: 101px;
      //   background-color: #f2f2f2;
      //   border-radius: 10px;
      //   opacity: 0.15;
      // }

      .cp-flex-3 {
        flex: 3;

        .cp-name {
          font-size: 17px !important;
          font-family: 'Arial-Bold' !important;
          margin-bottom: 14px !important;
        }

        .cp-by {
          color: #bfc2c3 !important;
          opacity: 0.8;
          font-size: 15px !important;
        }
      }

      .cp-flex-1 {
        flex: 1;

        .cp-border {
          width: 1px;
          height: 55px;
          background-color: #ffffff !important;
          margin: 0 1rem;
        }

        .cp-status {
          color: #1abfca !important;
          opacity: 0.9;
          font-family: 'Arial-Bold' !important;
          font-size: 17px !important;
          text-transform: capitalize;
          letter-spacing: 0px;
        }

        .cps-text {
          font-size: 14px !important;
          margin-bottom: 0.5rem !important;
          letter-spacing: 0px;
        }
      }
    }

    .cp-total {
      min-height: 101px;
      position: relative;
      border-radius: 10px;
      padding: 21px 40px;
      margin-bottom: 10px !important;
      flex-direction: column;
      display: flex;
      justify-content: center;
      background: rgba(242, 242, 242, 0.15);

      .cp-t-title {
        font-size: 17px !important;
        font-family: 'Arial-Bold' !important;
        margin-bottom: 0.5rem !important;
        letter-spacing: 0;
      }

      .cp-t-count {
        color: #1bb3bf !important;
        font-size: 28px !important;
        font-family: 'Arial-Bold' !important;
        letter-spacing: -3px;
      }

      // .cp-inner {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   flex: 1;
      //   min-height: 101px;
      //   background-color: #f2f2f2;
      //   border-radius: 10px;
      //   opacity: 0.15;
      // }
    }

    .cp-info-box {
      min-height: 625px;
      position: relative;
      border-radius: 10px;
      padding: 10px 20px 25px;
      background-color: rgba(242, 242, 242, 0.15);

      .flex-column {
        flex-direction: column;

        img {
          width: 110px;
          height: 110px;
          object-fit: cover;
          margin-top: 30px !important;
          margin-bottom: 20px !important;
          border-radius: 50%;
        }
      }

      .in-text {
        font-size: 18px !important;
        line-height: 25px !important;
        font-family: 'Arial-Bold' !important;
        margin-bottom: 15px !important;
        text-align: center;
        min-height: 50px;

        span {
          display: block;
          font-family: 'Arial-Bold' !important;
        }
      }

      .cp-status {
        color: rgba(26, 191, 202, 90%);
        font-family: 'Arial-Bold' !important;
        font-size: 17px !important;
        text-transform: capitalize;
      }

      .p-box:first-child {
        margin-right: 5px !important;
      }

      .p-box:last-child {
        margin-left: 5px !important;
      }

      .team-text {
        margin: 1rem 0 0 0;
        text-align: center;
        color: rgba(255, 255, 255, 60%) !important;
      }

      .team-box {
        column-gap: 5px;

        .flex-column {
          flex: 1;
          text-align: center;
        }

        .team-profile {
          width: 52px;
          height: 52px;
          object-fit: cover;
          border-radius: 50%;
        }

        .team-name {
          font-size: 12px !important;
          min-height: 30px;
          line-height: 14px;
          margin-bottom: 5px;
        }

        .team-role {
          font-size: 11px !important;
          color: #1abfc7 !important;
        }
      }

      .p-box {
        min-height: 68px;
        position: relative;
        border-radius: 10px;
        padding: 1rem;
        flex: 1 1;
        margin: 1rem 0;
        background: rgba(255, 255, 255, 15%);

        .italic-status {
          color: #1abfc7 !important;
          font-family: 'Arial-Bold' !important;
          font-size: 14px !important;
          font-style: italic;
        }

        // .p-inner {
        //   min-height: 68px;
        //   background: #f2f2f2;
        //   opacity: 0.15;
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        //   left: 0;
        //   bottom: 0;
        //   border-radius: 10px;
        // }
      }

      // .cp-inner {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   flex: 1;
      //   min-height: 545px;
      //   background-color: #f2f2f2;
      //   border-radius: 10px;
      //   opacity: 0.15;
      // }
    }
  }

  .payment-info {
    min-width: 80px;
    margin: 0 15px;
    position: relative;

    &:before {
      content: '';
      border-right: 1px solid #eee;
      height: 100%;
      position: absolute;
      top: 0;
      left: -40px;
    }

    &:first-child:before {
      border-right: 0px solid !important;
    }
  }

  .pay-flex {
    display: flex;
    justify-content: space-between;
    flex-basis: 285px;
  }

  .pay-container {
    h2 {
      font-size: 17px !important;
      font-family: 'Arial-Bold' !important;
      margin-bottom: 14px !important;
    }

    h3 {
      font-family: 'Arial-Regular';
      font-size: 14px !important;
      line-height: 16px;
    }

    h6 {
      color: rgba(26, 191, 202, 90%);
      font-family: 'Arial-Bold';
      text-transform: capitalize;
      line-height: 30px;
      font-size: 17px;
    }

    p {
      color: #bfc2c3 !important;
      opacity: 0.8;
      font-size: 15px !important;
    }
  }

  .common-footer {
    margin: 1rem 0 !important;

    p {
      font-size: 15px !important;
    }
  }

  .c-title {
    font-size: 22px !important;
    font-family: 'Arial-Bold' !important;
    margin: 20px 0;
  }

  .kpi-select {
    margin-left: 0.5rem;
    background-color: unset;
    border: unset;
    color: #1abfc7 !important;
    font-size: 20px !important;
    cursor: pointer;
    text-align: center;

    option {
      font-size: 16px !important;
    }

    &:focus-visible {
      outline: unset;
      border: unset;
    }
  }

  //dark and light theme
  .dark-theme {
    .black-white-text {
      color: #ffffff !important;
      line-height: 22px;
    }
  }

  .light-theme {
    .black-white-text {
      color: #000000 !important;
    }

    .t-title {
      color: #000000 !important;
    }
  }

  .mainpadding {
    padding: 6rem 4.8rem 0 4.8rem;
  }
}

input {
  &::placeholder {
    font-size: 14px !important;
    font-family: Arial-Regular !important;
  }
}

.mnone-btn {
  text-transform: none !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

//modal
.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 636px;
  min-height: 180px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #fff;

  .modal-title {
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .m-border {
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .modal-footer {
    padding: 1rem 2rem;

    .mcancel-btn {
      color: #343434;
      opacity: 0.58;
      text-transform: capitalize !important;
    }

    .madd-btn {
      min-width: 131px;
      background-color: #002c69;
      border-radius: 6px;
      color: #ffffff !important;
      padding: 6px 18px !important;
      text-transform: capitalize !important;
    }
  }
}

.custom-textarea-note {
  width: 100%;
  border: 1px solid #cccccc;

  .Mui-disabled {
    background-color: lightgrey !important;
  }

  .MuiOutlinedInput-root {
    background-color: #ffffff;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    padding: 7px 14px;
    font-size: 14px;
  }
}

.browse-btn {
  &:hover {
    background-color: lightgrey !important;
    // margin-left: 0.8rem;
  }
}

.filter-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 46.55vw;
  min-height: 357px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #fff;

  .mcancel-btn {
    color: #343434;
    opacity: 0.58;
    text-transform: capitalize !important;
  }

  .madd-btn {
    min-width: 131px;
    height: 35px;
    background-color: #002c69;
    border-radius: 6px;
    color: #ffffff !important;
    text-transform: capitalize !important;
  }
}

.custom-modal-note {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-height: 270px;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #fff;

  .modal-title {
    padding: 15px 30px;
    border-bottom: 1px solid #cccccc;
    font-size: 20px !important;
    letter-spacing: 0;
  }

  .m-border {
    padding: 18px 30px;
    border-bottom: 1px solid #cccccc;
  }

  .modal-footer {
    padding: 1rem 2rem;

    .mcancel-btn {
      color: #343434;
      opacity: 0.58;
      text-transform: capitalize !important;
    }

    .madd-btn {
      min-width: 131px;
      height: 35px;
      background-color: #002c69;
      border-radius: 6px;
      color: #ffffff !important;
      text-transform: capitalize !important;
      margin-left: 20px;
    }
  }
}

.MuiCheckbox-root.Mui-checked {
  svg {
    fill: #174baf;
  }
}
.cancel-icon {
  cursor: pointer;
  fill: gray !important;
  height: 30px;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 30px;
}
.custom-pay-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 387px;
  min-height: 201px;
  border-radius: 10px;
  background-color: #fff;
  padding: 1.5rem;

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }

  .pm-txt {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
    margin: 1.5rem 0;
  }
}

.payment-public {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fcfcfc;

  .suc-title {
    font-size: 26px !important;
    font-weight: bold;
    padding-bottom: 24px;
  }

  .suc-dis {
    font-size: 15px !important;
    line-height: 20px !important;
    margin-bottom: 24px;
  }

  .success-btn-new {
    width: 150px;
    height: 43px;
    border: 2px solid rgba(25, 82, 167, 1);
    border-radius: 5px;
    cursor: pointer;
    text-transform: capitalize !important;
    font-size: 16px !important;
    margin-bottom: 40px;
    background-color: rgba(25, 82, 167, 1);
    color: #fff !important;

    &:hover {
      background-color: rgba(25, 82, 167, 1);
      color: #fff !important;
    }
  }

  .pay-button {
    min-width: 210px !important;
    height: 47px !important;
    background-color: #25a163;
    color: #ffffff !important;
    text-transform: capitalize;
    border-radius: 5px !important;
    font-family: Arial-Bold !important;
    font-size: 16px !important;
    &:hover {
      background-color: #25a163;
    }
  }

  .cancel-pay-button {
    min-width: 95px !important;
    height: 47px !important;
    background-color: rgb(132, 137, 140);
    color: #ffffff !important;
    text-transform: capitalize;
    border-radius: 5px !important;
    font-family: Arial-Bold !important;
    font-size: 16px !important;
    &:hover {
      background-color: #64666599;
    }
  }

  .ab2-title {
    font-size: 22px !important;
    font-weight: bold;
    color: rgba(12, 17, 21, 1) !important;
  }

  .ab-title {
    font-size: 22px !important;
    font-weight: bold;
    color: rgba(12, 17, 21, 1);
    text-transform: uppercase;
  }

  .ab-info {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .bill-label {
    font-size: 15px !important;
    color: rgba(51, 51, 51, 1);
    opacity: 0.8;
    padding-bottom: 8px;
  }

  .summary-box {
    min-height: 217px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 6px;
    padding: 30px 25px 20px 25px;

    .sum-text {
      font-size: 15px !important;
      color: rgba(51, 51, 51, 1);
    }
  }

  .bill-text {
    font-size: 15px !important;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    opacity: 0.8;
  }

  .change-btn {
    width: 248px;
    height: 35px;
    border: 1px solid #ccc;
    font-size: 14px !important;
    padding: 0 12px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;

    .over-box {
      display: none;
    }

    &:hover {
      .over-box {
        position: absolute;
        bottom: -159px;
        left: 0px;
        width: 100%;
        display: block;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 8px;
        font-size: 14px !important;

        p {
          padding-left: 8px;
        }
      }
    }
  }

  .plan-card {
    background-color: rgba(247, 251, 255, 1);
    border: 2px solid #ccc;
    width: 320px;
    padding: 24px;
    min-height: 200px;
    border-radius: 10px;
    margin-top: 18px;

    .plan-title {
      font-size: 22px !important;
      font-weight: bold;
      color: rgba(12, 17, 21, 1);
    }

    .plan-info {
      font-size: 15px !important;
      line-height: 20px !important;
      padding-bottom: 16px;
      color: grey;
    }

    .fon-500 {
      font-weight: 600;
    }

    .idea-plan {
      font-size: 15px !important;
      line-height: 20px !important;
      padding-bottom: 16px;
    }
  }

  .pay-input {
    width: 64px;
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 5px 0 12px;
  }

  .cuarrow {
    position: absolute;
    bottom: -36px;
    right: -52px;
  }

  .pb-rel {
    position: relative;
    padding-left: 20px;

    .switchpay {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    .switchpay input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input + .slider {
      background-color: rgba(25, 82, 167, 1);
    }

    input:focus + .slider {
      box-shadow: 0 0 1px rgba(25, 82, 167, 1);
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .percen-25 {
    position: absolute;
    bottom: -36px;
    right: -125px;
    color: rgba(25, 82, 167, 1) !important;
    font-size: 15px !important;
    font-weight: 600;
  }

  .pb-card-box {
    width: 379px;
    min-height: 406px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(225, 225, 225, 1);
    border-radius: 6px;
    padding: 35px 25px;
    margin-bottom: 15px;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: rgba(247, 251, 255, 1);
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    }

    .prise-info {
      font-size: 40px !important;
      font-weight: bold;
      padding-bottom: 28px;
    }

    .sub {
      font-size: 16px !important;
      color: rgba(42, 42, 42, 1) !important;
    }

    .pb-card-title {
      font-size: 26px !important;
      font-weight: bold;
      color: rgba(25, 82, 167, 1) !important;
      padding-bottom: 5px;
    }

    .pb-card-info {
      font-size: 16px !important;
      color: rgba(42, 42, 42, 1) !important;
      padding-bottom: 14px;
    }

    .tick-box {
      width: 32px;
      height: 32px;
      background-color: rgba(232, 237, 251, 1);
      border-radius: 16px;
    }

    .tick-info {
      flex: 1;
      font-size: 16px !important;
      color: rgba(42, 42, 42, 1) !important;
      padding-left: 11px;
    }

    .prise-btn {
      width: 100%;
      height: 43px;
      border: 2px solid rgba(25, 82, 167, 1);
      border-radius: 3px;
      cursor: pointer;
      text-transform: capitalize !important;
      font-size: 16px !important;
      color: rgba(25, 82, 167, 1);
      margin-bottom: 40px;

      &:hover {
        background-color: rgba(25, 82, 167, 1);
        color: #fff !important;
      }
    }

    .idea-info {
      font-size: 16px !important;
      line-height: 20px !important;
      color: rgba(42, 42, 42, 1) !important;
      padding-bottom: 20px;
    }
  }

  .pb-title {
    font-size: 40px !important;
    padding-bottom: 20px;
    padding-top: 41px;
    color: rgba(12, 17, 21, 1) !important;
  }

  .pb-title1 {
    font-size: 26px !important;
    color: rgba(42, 42, 42, 1) !important;
    padding-bottom: 24px;
  }

  .pb-title2 {
    font-size: 22px !important;
    color: rgba(42, 42, 42, 1) !important;
  }

  .pb-bold {
    font-size: 22px !important;
    color: rgba(42, 42, 42, 1) !important;
    font-weight: bold !important;
  }

  .pb-normal {
    font-size: 22px !important;
    color: rgba(42, 42, 42, 1) !important;
  }
}

.subscription-box {
  background-color: #ffffff;

  .progress {
    height: 7px;
    border-radius: 10px;
  }
  .assem-badge {
    color: #017a47;
    background-color: #d3fadb;
    width: auto;
    height: 25px;
    min-width: 80px;
    border-radius: 25px;
    text-transform: capitalize !important;
    padding: 0px 10px !important;
    box-shadow: unset;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: #d7f7e0;
      cursor: unset;
    }
  }

  .ab-title {
    font-size: 22px !important;
    font-weight: bold;
    color: rgba(12, 17, 21, 1);
    text-transform: uppercase;
  }

  .ab-info {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .plan-card {
    background-color: #ffffff;
    border: 2px solid #ccc;
    width: 800px;
    padding: 25px 25px 25px 30px;
    height: 250px;
    border-radius: 4px;
    margin-top: 18px;

    .plan-title {
      font-size: 22px !important;
      font-weight: bold;
      color: rgba(12, 17, 21, 1);
    }

    .plan-info {
      font-size: 15px !important;
      line-height: 20px !important;
      padding-bottom: 16px;
      color: #848080 !important;
    }

    .fon-500 {
      font-weight: 600;
    }

    .idea-plan {
      font-size: 15px !important;
      line-height: 20px !important;
      padding-bottom: 16px;
    }
  }

  .pb-title {
    font-size: 40px !important;
    padding-bottom: 20px;
    padding-top: 41px;
    color: rgba(12, 17, 21, 1) !important;
  }

  .pb-title1 {
    font-size: 26px !important;
    color: rgba(42, 42, 42, 1) !important;
    padding-bottom: 24px;
  }

  .pb-title2 {
    font-size: 22px !important;
    color: rgba(42, 42, 42, 1) !important;
  }

  .pb-bold {
    font-size: 22px !important;
    color: #343434 !important;
  }

  .pb-normal {
    font-size: 22px !important;
    color: rgba(42, 42, 42, 1) !important;
  }
}

.success-re-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 387px;
  min-height: 201px;
  border-radius: 10px;
  background-color: #fff;
  padding: 1.5rem;

  .d-flex-a {
    flex-direction: column;
  }

  &:focus-visible {
    outline: none !important;
    border: none !important;
  }

  .s-title {
    font-size: 14.5px !important;
    padding: 0.2rem 0 0.2rem 0;
  }

  .madd-btn {
    min-width: 100px !important;
    height: 35px !important;
    background-color: #002c69 !important;
    border-radius: 6px;
    color: #ffffff !important;
    text-transform: capitalize !important;
  }

  .pm-txt {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 14px !important;
  }
}

.custom-file-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  min-height: 201px;
  border-radius: 10px;
  background-color: #fff;
  padding-bottom: 1rem;

  .pm-txt {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
    margin: 1.5rem 0;
  }

  .modal-title {
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .m-border {
    padding: 1rem 2rem;
  }

  .modal-footer {
    padding: 1rem 2rem;

    .mcancel-btn {
      min-width: 131px !important;
      padding: 6px 20px !important;
      color: #343434 !important;
      opacity: 0.58;
      text-transform: capitalize !important;
    }

    .madd-btn {
      min-width: 131px !important;
      padding: 6px 20px !important;
      background-color: #002c69 !important;
      border-radius: 6px;
      color: #ffffff !important;
      text-transform: capitalize !important;
    }
  }
}

.affliate {
  margin-left: 12px;
  font-style: italic;
  font-size: 18px;
}

//payment
.payment-box {
  .top-box {
    background-color: #f3f3f4;
    border-radius: 8px 8px 0px 0px;
    min-height: 250px;
    padding: 32px 60px;

    .pay-dis {
      color: #333333 !important;
      opacity: 0.8;
      line-height: 22px !important;
    }

    .oname-label,
    .oname-text {
      color: #333333 !important;
      opacity: 0.8;
      padding-bottom: 0.5rem;
    }
  }

  .bottom-box {
    background-color: #ffffff;
    border-radius: 0px 0px 8px 8px;
    min-height: 150px;
    padding: 38px 79px;

    .pay-ri {
      color: #333333 !important;
      opacity: 0.8;
      line-height: 22px !important;
      // text-align: right;
      padding-right: 2rem;
      // padding-left: 5rem;
      word-break: break-word;
      font-size: 15px !important;
    }

    .pay-head {
      font-weight: 800;
      font-size: 18px !important;
      padding-bottom: 15px;
    }

    .view-btn {
      width: 93px;
      height: 35px;
      background-color: #002c69;
      color: #ffffff !important;
      text-transform: capitalize;
    }

    .pay-btn {
      width: 93px;
      height: 35px;
      background-color: #25a163;
      color: #ffffff !important;
      text-transform: capitalize;
    }
  }

  .payment-choose-card {
    background-color: #f3f3f4;
    border-radius: 8px 8px 8px 8px;
    max-width: 475px;
    min-height: 138px;

    .arrow-hide {
      display: none;
    }
  }

  .payment-choose-card:hover {
    box-shadow: 0px 1px 5px #00000066;

    .arrow-hide {
      display: flex !important;
    }
  }
}

.payment-me-box {
  .top-box:first-child {
    border-radius: 8px 8px 0 0;
  }

  .top-box {
    background-color: #f3f3f4;
    min-height: 100px;
    padding: 34px 46px 15px 46px;

    .oname-label,
    .oname-text {
      color: #333333 !important;
      opacity: 0.8;
      padding-bottom: 0.5rem;
    }
  }

  .captca {
    margin: 1rem 0;
  }

  .bottom-box {
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    min-height: 150px;
    padding: 34px 46px 34px 46px;

    .pf-t {
      padding-top: 0.3rem !important;
    }

    .cardim {
      margin-bottom: 1.5rem;
    }

    .pay-am-btn {
      min-width: 210px !important;
      height: 47px !important;
      background-color: #25a163;
      color: #ffffff !important;
      text-transform: capitalize;
      border-radius: 5px !important;
      font-family: Arial-Bold !important;
      font-size: 16px !important;
    }
  }
}

.pview-vox {
  background-color: #ffffff;
  padding: 25px 35px;
  min-height: 300px;

  .paydis {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 26px;
  }

  .subdis {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
  }

  .mail-info {
    color: #333333 !important;
    font-style: italic !important;
    font-size: 12px !important;
    opacity: 0.5;
    line-height: 22px;
  }
}

.term-box {
  border-radius: 8px;
  padding: 34px 42px;
  background-color: #f4f5f7;

  .term-txt {
    color: #333333 !important;
    opacity: 0.8;
    line-height: 22px;
  }
}

.pay-met-box {
  min-height: 102px;
  padding: 19px 49px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;

  .pay-in-box {
    background-color: #fff;
    border-radius: 10px;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .pay-dis-text {
    // color: #bfc2c3 !important;
    // opacity: 0.6;
    line-height: 1.5 !important;
  }

  .opacity-text {
    opacity: 0.6;
  }

  .percentage {
    font-weight: 600;
    opacity: 0.8;
  }

  .sv {
    margin-top: -3px;

    svg {
      width: 19px !important;
    }
  }
}

.radio-bottom {
  margin-bottom: 10px !important;

  .MuiFormControlLabel-label {
    line-height: 23px !important;
  }
}

.radio-reset {
  font-size: 13px !important;
  color: #a19e9e !important;
  font-weight: 600;
  font-style: italic;
  cursor: pointer;
  padding-left: 12px;
  padding-top: 2px;
}

//text-align
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

//paddin classes
.pt-0 {
  padding-top: unset !important;
}

.pd {
  padding: 0.5rem !important;
}

.pl-0 {
  padding-left: unset !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-6 {
  padding-left: 5px !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}
.pl-20 {
  padding-left: 20px !important;
}

.pl-5 {
  padding-left: 5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pr-2rem {
  padding-right: 2rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pt-6 {
  padding-top: 5.5rem !important;
}

.pb-0 {
  padding-bottom: 0.2rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

//margin-classes
.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 2.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

//flex-classes
.flex-column {
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.content-center {
  justify-content: center;
}

.content-end {
  justify-content: end;
}

.d-flex-jr {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
}

.d-flex-a {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.d-flex-b {
  display: flex;
  align-items: center;
}

.d-flex-jae {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-end {
  justify-content: flex-end;
}

.d-flex-sb {
  justify-content: space-between;
}

.d-flex-sa {
  justify-content: space-around;
}

.d-flex-1 {
  flex: 1;
}

.d-flex-2 {
  flex: 2;
}

.d-flex-3 {
  flex: 3;
}

//font-size
.f-14 {
  font-size: 14px !important;
}

.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-20 {
  font-size: 20px !important;
}

.f-18 {
  font-size: 1.12em !important;
}

.f-18-1 {
  font-size: 18px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-31 {
  font-size: 2rem !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-28 {
  font-size: 28px !important;
}

.font-bold {
  font-family: Arial-Bold !important;
}

.blue-text {
  color: #4d71f1 !important;
  font-size: 20px !important;
}

.f-bold {
  font-family: Arial-Bold !important;
}

.plus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #bfc2c3 !important;
  color: #4a4a4a !important;
  margin-right: 0.5rem !important;
  font-size: 19px !important;
}

.add-template-modal-text {
  background-color: #e2e2e2;
  height: 35px;
  color: #333333 !important;
  font-size: 14px !important;
  padding: 0px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.login {
  z-index: 100;
  position: relative;
  // margin: 3rem 1rem 0rem 5rem;
  margin: auto;

  .s-text {
    color: #ffffff !important;
    font-size: 16px !important;
  }

  .submit {
    margin: 1rem 0;
    background-color: #164baf !important;
    height: 36px;
  }

  .label-text {
    color: #fff;
  }

  .top-text {
    color: #fff;
    margin-bottom: 1rem !important;
    font-size: 17px !important;
  }

  .l-input {
    min-height: 40px;
    border-radius: 5px;
    width: 100%;

    .MuiFilledInput-root:after {
      border-bottom: unset;
    }

    .MuiFilledInput-input {
      padding-top: 9px !important;
      background-color: #fff;
      border-radius: 5px;
    }

    .MuiFilledInput-root {
      background-color: #fff;
      border-radius: 5px;
    }
  }

  button:focus {
    outline: 0;
  }

  .col-form-label {
    text-align: left;
    width: 100%;
  }

  .invalid-feedback {
    color: #ff0000 !important;
    font-size: 11px !important;
  }

  .mb-3 {
    margin-top: 1.5rem !important;
  }

  .formheight {
    .forgotlable {
      text-align: right;
      float: right;
      font-size: 12px !important;

      // text-decoration: underline;
      a {
        color: #696565;
      }
    }
  }

  .middle {
    display: inline-flex;
    width: 100%;
    align-items: center;

    .line {
      height: 2px;
      background-color: #8c8c8c;
      display: block;
      width: calc(100% - 50px);
    }

    .name {
      width: 100px;
      color: #fff;
      text-align: center;
    }
  }

  .fotter {
    width: 100%;

    a {
      text-decoration: underline !important;
      color: #fff !important;
      cursor: pointer;
    }
  }
}

.invalid-text {
  color: #ff0000 !important;
  font-size: 11px !important;
}

.bannervideo {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  background-color: #002666;
}

.login-temp {
  width: 414px;
}

.singup-page {
  width: 560px;
}

.temp {
  background-color: #6b6b6c !important;
  // width: 280px;
  // padding: 25px;
  // border-radius: 10px;
  padding: 35px;
  box-shadow: 0 5px 14px 0 rgb(0 0 0 / 22%);
  border-radius: 8px;

  h4 {
    padding-top: unset !important;
  }

  .forgotlable {
    a {
      color: #fff !important;
    }
  }
}

.l-footer {
  padding: 1rem 5rem;
  margin: 1rem 0 !important;
  position: relative;

  div {
    color: #fff !important;
    font-size: 15px !important;
  }

  .div {
    div {
      cursor: pointer;
    }
  }
}

p {
  &.Mui-error {
    color: red !important;
    margin-left: unset !important;
    font-size: 12px !important;
  }
}

span {
  &.Mui-error {
    color: red !important;
    margin-top: 0px !important;
  }
}

.MuiTablePagination-select {
  min-width: 30px !important;
  text-align: left !important;
  text-align-last: left !important;
}

.tras-btn {
  border: 1px solid lightgray;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.5rem;
  width: 35px !important;
  height: 35px !important;
  transition: 0.5s;

  &:hover {
    background-color: lightgrey;
    fill: red;
  }
}

.required {
  color: red !important;
}

.MuiCalendarPicker-root {
  background-color: #ffffff !important;
}

.MuiPickersDay-root {
  border: 1px solid lightgray;
}

.Mui-selected {
  background-color: #002c69 !important;
}

.MuiGrid-root {
  margin-top: unset !important;
  margin-left: unset !important;
  width: 100% !important;
}

.cancel-btn {
  min-width: 120px !important;
  border-radius: 5px !important;
  height: 40px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  position: relative;
  background-color: #ffffff !important;
  // .o-box {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   min-width: 180px;
  //   min-height: 30px;
  //   background-color: #ffffff !important;
  //   border-radius: 10px;
  //   opacity: 0.1;
  // }
}

.commentAcc {
  .MuiAccordionSummary-root {
    min-height: 35px !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    svg {
      fill: #fff !important;
    }
  }

  .MuiAccordion-root {
    background-color: unset !important;
    border-bottom: 1px solid #4c4c4c;
  }

  .MuiAccordionSummary-content {
    margin: 10px 0px !important;

    p {
      color: #fff !important;
    }
  }

  .MuiAccordionDetails-root {
    p {
      color: #cccccc !important;
    }
  }
}

.n-cancel-btn {
  min-width: 120px !important;
  height: 40px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc;
  position: relative;
  text-transform: capitalize !important;

  // .o-box {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: #ffffff !important;
  //   border-radius: 10px;
  //   opacity: 0.1;
  // }
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-8 {
  margin-right: 0.3rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}
.ml-3 {
  margin-left: 2rem !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}

.my-2 {
  margin: 1rem 0 !important;
}
.curser-disable {
  cursor: no-drop;
}

.add-btn {
  min-width: 120px !important;
  height: 40px !important;
  background-color: #002c69 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  padding: 8px 15px !important;
}

.n-add-btn {
  min-width: 120px !important;
  height: 40px !important;
  background-color: #002c69 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

//form css
a {
  text-decoration: unset;
}

.react-tel-input .form-control {
  border-radius: 5px !important;
  width: 100% !important;
  height: 37px !important;
}

form {
  .multiselect-container,
  .search-wrapper {
    border-radius: 5px !important;

    .searchBox {
      padding: 2.5px 12px;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .pr-1 {
    padding-right: 1.5rem !important;
  }

  .pl-1 {
    padding-left: 1.5rem !important;
  }

  .pl-4 {
    padding-left: 3.6rem !important;
  }

  .pr {
    padding-right: 1rem !important;
  }

  .pl {
    padding-left: 1rem !important;
  }

  .MuiFormGroup-row {
    min-height: 35px;
  }

  .MuiSelect-select {
    border-radius: unset !important;
  }

  .MuiButtonBase-root {
    padding: 0 9px !important;
  }

  .custom-field {
    margin-bottom: 16px;

    .react-tel-input .form-control {
      border-radius: 5px !important;
      width: 100% !important;
    }

    .form-conteol {
      border-radius: 5px !important;
      width: 100% !important;
    }

    .MuiOutlinedInput-root {
      border-radius: 5px !important;
    }

    .custom-label {
      color: #333333 !important;
      font-size: 15px !important;
      font-family: Arial-Bold !important;
      margin-bottom: 8px !important;
    }

    .custom-input {
      width: 100%;

      .MuiOutlinedInput-root {
        height: 35px !important;
        width: 100%;
        background-color: #ffffff !important;
        border: 1px solid #cccccc !important;
        border-radius: 5px !important;
        // &.Mui-disabled {
        //   border: hsl(0, 0%, 95%) !important;
        // }
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
        top: 0px !important;
      }

      input {
        color: #333333 !important;
        font-size: 14px !important;
        padding: 6.2px 14px !important;
        border-radius: 5px;

        &.Mui-disabled {
          background-color: hsl(0, 0%, 95%);
        }
      }
    }
    .custom-input1 {
      width: 100%;

      .MuiOutlinedInput-root {
        height: 35px !important;
        width: 100%;
        background-color: #ffffff !important;
        border: 1px solid #cccccc !important;
        border-radius: 5px !important;
        // &.Mui-disabled {
        //   border: hsl(0, 0%, 95%) !important;
        // }
      }
      .MuiOutlinedInput-input:focus {
        outline: 1px solid #1964cd !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
        top: 0px !important;
      }

      input {
        color: #333333 !important;
        font-size: 14px !important;
        padding: 6.2px 14px !important;
        border-radius: 5px;

        &.Mui-disabled {
          background-color: hsl(0, 0%, 95%);
        }
      }
    }
    .custom-textarea {
      width: 100%;
      // border: 1px solid #cccccc;

      .Mui-disabled {
        background-color: lightgrey !important;
      }

      .MuiOutlinedInput-root {
        background-color: #ffffff;
        border: 1px solid #cccccc !important;
        border-radius: 5px !important;
        padding: 7px 14px;
        font-size: 14px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: unset !important;
      }

      input {
        color: #333333 !important;
        font-size: 14px !important;
        padding: 7.5px 14px !important;
      }
    }
  }

  .custom-field-additional {
    margin-bottom: 0rem !important;
  }
}

.custom-textarea1 {
  width: 100%;
  border: 1px solid #cccccc;
  padding: 7.5px 14px;
  border-radius: 5px;

  &:focus-visible {
    outline: 1px solid #cccccc !important;
  }

  .Mui-disabled {
    background-color: lightgrey !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
  }
}

.custom-textarea2 {
  width: 100%;
  border: 1px solid #cccccc;
  padding: 7.5px 14px;
  border-radius: 5px;

  &:focus-visible {
    outline: 1px solid #0664e7 !important;
  }

  .Mui-disabled {
    background-color: lightgrey !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
  }
}
.outer-custom-input {
  height: 35px !important;
  width: 100px;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: unset !important;

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
  }
}

.signatureCanvas {
  border: 1px solid #cccccc !important;
  background-color: #ffffff;
}

.MuiCheckbox-root {
  svg {
    fill: #cccccc;
  }
}

.custom-alignment {
  margin: 15px 0 !important;
}

.custom-field-row {
  margin: 1rem 0 !important;

  .form-control {
    border-radius: 5px !important;
  }

  .custom-label {
    color: #333333 !important;
    font-size: 15px !important;
    font-family: Arial-Bold !important;
    margin-bottom: 8px !important;
  }

  .custom-input {
    min-height: 35px !important;
    width: 100%;
    background-color: #ffffff !important;
    border-radius: 5px !important;

    .MuiOutlinedInput-root {
      border: 1px solid #cccccc !important;
      border-radius: 5px !important;
    }

    .MuiSelect-select {
      padding: 6px 14px !important;
      border: 1px solid #cccccc !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    input {
      color: #333333 !important;
      font-size: 14px !important;
      padding: 7.5px 14px !important;
    }
  }

  .custom-textarea {
    width: 100%;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    font-size: 14px;

    .Mui-disabled {
      background-color: lightgrey !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    .MuiOutlinedInput-root,
    input {
      color: #333333 !important;
      font-size: 14px !important;
      padding: 7.5px 14px !important;
    }
  }
}

.MuiSelect-select {
  padding: 8px 14px !important;
}

.mcustom-textarea {
  width: 100%;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  padding: 7px 14px;
  font-size: 14px;
  resize: none !important;

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
    resize: none !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
    resize: none !important;
  }
}

.bo-les {
  border-radius: 0 4px 4px 0 !important;
}

.form-wrapper {
  iframe {
    width: 100%;
  }
}

.bo-rles {
  border-radius: 4px 0 0 4px !important;
}

.custom-search-input {
  height: 36px !important;
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 4px !important;

  &.in-pad-se {
    input {
      padding: 7.5px 5px 7.5px 30px !important;
    }
  }

  svg {
    fill: #3d3f40 !important;
  }

  .MuiSelect-select {
    padding-left: 15px !important;
    color: #fff !important;
  }

  .MuiInputBase-adornedStart {
    padding-left: unset !important;
  }

  .MuiInputAdornment-root {
    margin-right: unset !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #3d3f40 !important;
    font-size: 14px !important;
    padding: 8.8px 5px 8.8px 8px;

    &::placeholder {
      font-size: 15px !important;
    }
  }
}

.custom-pdf-input {
  height: 26px !important;
  width: 36px !important;
  background-color: #081e2d !important;
  border-radius: 4px !important;

  &.in-pad-se {
    input {
      padding: 7.5px 5px 7.5px 30px !important;
    }
  }

  svg {
    fill: #3d3f40 !important;
  }

  .MuiSelect-select {
    padding-left: 15px !important;
    color: #fff !important;
  }

  .MuiInputBase-adornedStart {
    padding-left: unset !important;
  }

  .MuiInputAdornment-root {
    margin-right: unset !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #ffffff !important;
    font-size: 14px !important;
    padding: 4px 4px 4px 4px;
    text-align: center;

    &::placeholder {
      font-size: 15px !important;
    }
  }
}

.pdf-zoom {
  font-size: 14px !important;
  background-color: #081e2d !important;
  color: #ffffff !important;
  height: 26px !important;
  width: 70px !important;
  padding: 4px 4px 4px 4px;
  text-align: center;
}

.assemble-search {
  height: 32px !important;
  width: 100%;
  background-color: #ffffff !important;
  border-radius: 4px !important;

  &.in-pad-se {
    input {
      padding: 7.5px 5px 7.5px 30px !important;
    }
  }

  .MuiInputBase-adornedStart {
    padding-left: unset !important;
  }

  .MuiInputAdornment-root {
    margin-right: unset !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #3d3f40 !important;
    font-size: 14px !important;
    padding: 8.8px 5px 8.8px 8px;

    &::placeholder {
      font-size: 15px !important;
    }
  }
}

.custom-search-model {
  height: 36px !important;
  width: 100%;
  background-color: #84898c !important;
  border-radius: 4px !important;

  &.in-pad-se {
    input {
      padding: 7.5px 5px 7.5px 30px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  input {
    color: #ffffff !important;
    font-size: 14px !important;
    padding: 8.8px 5px 8.8px 8px;

    &::placeholder {
      font-size: 15px !important;
    }
  }
}

.searc-box1 {
  padding-left: 1.5rem;
  position: relative;
  width: 260px;
}

.send-invite .custom-label {
  text-align: center;
}

.send-invite {
  margin-top: 0px !important;
  p {
    display: table;
    margin: 15px auto;
  }
}

.searc-box2 {
  padding-left: 1.5rem;
  position: relative;
  width: 295px;
}

.searc-box3 {
  width: 220px;
}

.role-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 50px;
    background-color: #e9eaeb;

    th {
      color: #333333;
      text-transform: uppercase;
      opacity: 0.5;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
    }
  }

  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 4.5rem !important;
  }

  th {
    text-align: left;
  }

  // td,
  th {
    padding: 1rem;
    text-transform: capitalize;
  }

  td {
    .MuiCheckbox-root {
      padding: unset !important;
    }
  }

  // td {
  //   font-size: 0.9em !important;

  //   .blue-text {
  //     font-size: 0.9em;
  //   }
  // }
}

.section-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff !important;

  .custom-input {
    height: 35px !important;
    width: 100%;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: unset !important;

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    input {
      color: #333333 !important;
      font-size: 14px !important;
      padding: 7.5px 14px !important;
    }
  }

  thead {
    height: 50px;
    background-color: rgba(226, 242, 255, 0.9);
    border-bottom: 1px solid #e5e5e5;

    .MuiTableCell-head {
      background-color: #e9eaeb;
    }

    th {
      color: #8e8e8f;
      text-transform: uppercase;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
      text-align: left;
    }
  }

  // td,
  th {
    padding: 1rem;
    text-transform: capitalize;
  }

  td {
    // font-size: 0.9em;
    width: 17%;

    // .blue-text {
    //   font-size: 0.9em;
    // }
  }
}

.outform-select {
  border-radius: 5px;

  div {
    border-radius: 5px !important;
  }
}

.lead-table {
  width: 100%;
  border-collapse: collapse;

  .W-5 {
    width: 50%;
  }

  .W-4 {
    width: 40%;
  }

  .W-3 {
    width: 30%;
  }

  .W-2 {
    width: 20%;
  }

  .W-15 {
    width: 15%;
  }

  .W-1 {
    width: 10%;
  }

  a {
    color: #333333 !important;
  }

  .custom-input {
    width: 100%;

    .MuiOutlinedInput-root {
      height: 35px !important;
      width: 100%;
      background-color: #ffffff !important;
      border: 1px solid #cccccc !important;
      border-radius: unset !important;
    }
  }

  thead {
    height: 40px;
    background-color: rgba(226, 242, 255, 90%) !important;

    .MuiTableCell-head {
      background-color: rgba(226, 242, 255, 90%);
      border-bottom: 1px solid #e5e5e5;
    }

    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }

    th {
      color: #333333;
      text-transform: capitalize;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
      text-align: left;
      padding: 10px 15px !important;
    }
  }

  // td,
  th {
    text-transform: capitalize;
    border: none;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #e9f1ff;
    }

    tr:nth-child(even) {
      background-color: #fbfbff;
    }
  }

  .w25 {
    width: 25% !important;
  }

  td {
    // font-size: 0.9em;
    width: 17%;
    padding: 10px 15px !important;
    font-size: 14px !important;
    color: #333333;
    border-bottom: 1px solid #e5e5e5;
    line-height: 22px;

    .blue-text {
      font-size: 14px !important;
    }

    .longTextHide {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
      cursor: pointer;
    }

    .hideshow {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        -webkit-line-clamp: unset !important;
      }
    }
  }
}
.calendar {
  // display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  .fc-header-toolbar {
    background-color: #0b1922;
    color: white;
  }
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
  }
}

.calendar-sidebar {
  width: auto;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.new-report-table {
  width: 100%;
  border-collapse: collapse;

  .W-5 {
    width: 50%;
  }

  .W-4 {
    width: 40%;
  }

  .W-3 {
    width: 30%;
  }

  .W-2 {
    width: 20%;
  }

  .W-15 {
    width: 15%;
  }

  .W-1 {
    width: 10%;
  }

  a {
    color: #333333 !important;
  }

  .custom-input {
    width: 100%;

    .MuiOutlinedInput-root {
      height: 35px !important;
      width: 100%;
      background-color: #ffffff !important;
      border: 1px solid #cccccc !important;
      border-radius: unset !important;
    }
  }

  thead {
    height: 40px;
    background-color: rgba(226, 242, 255, 90%) !important;

    .MuiTableCell-head {
      background-color: rgba(226, 242, 255, 90%);
      border-bottom: 1px solid #e5e5e5;
    }

    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }

    th {
      color: #333333;
      text-transform: capitalize;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
      text-align: left;
      padding: 10px 15px !important;
      min-width: 150px;
    }
  }

  // td,
  th {
    text-transform: capitalize;
    border: none;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #e9f1ff;
    }

    tr:nth-child(even) {
      background-color: #fbfbff;
    }
  }

  .w25 {
    width: 25% !important;
  }

  td {
    // font-size: 0.9em;
    width: 17%;
    padding: 10px 15px !important;
    font-size: 14px !important;
    color: #333333;
    border-bottom: 1px solid #e5e5e5;
    line-height: 22px;

    .blue-text {
      font-size: 14px !important;
    }

    .longTextHide {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
      cursor: pointer;
    }

    .hideshow {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        -webkit-line-clamp: unset !important;
      }
    }
  }
}

.ov-logo {
  padding-left: 1rem;
  width: 155px;
}

.petition-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 50px;

    .MuiTableCell-head {
      background-color: rgba(242, 242, 242, 0.9);
    }

    th {
      color: #8e8e8f;
      text-transform: uppercase;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
      text-align: left;
    }
  }

  // td,
  th {
    padding: 1rem;
    text-transform: capitalize;
    border: none;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.4);
    }

    tr:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  td {
    // font-size: 0.9em;
    // width: 17%;
    color: #fff !important;

    // .blue-text {
    //   font-size: 0.9em;
    // }
  }
}

.report-table {
  width: 100%;
  border-collapse: collapse;

  .custom-input {
    height: 35px !important;
    width: 100%;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: unset !important;

    .MuiOutlinedInput-notchedOutline {
      border: unset !important;
    }

    input {
      color: #333333 !important;
      font-size: 14px !important;
      padding: 7.5px 14px !important;
    }
  }

  thead {
    height: 50px;
    background-color: #e9eaeb;

    th {
      color: #333333;
      text-transform: uppercase;
      opacity: 0.5;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
      text-align: left;
    }
  }

  // td,
  th {
    padding: 1rem;
    text-transform: capitalize;
  }

  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 2.5rem !important;
  }

  // td {
  //   font-size: 0.9em;

  //   .blue-text {
  //     font-size: 0.9em;
  //   }
  // }
}

.response-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    height: 50px;
    background-color: #e9eaeb;

    th {
      color: #333333;
      text-transform: uppercase;
      opacity: 0.5;
      font-size: 12px !important;
      font-family: Arial-Bold !important;
    }
  }

  // td,
  th {
    padding: 1rem;
    text-transform: capitalize;
  }

  tr {
    border-bottom: 1px solid lightgrey;
  }

  // td {
  //   font-size: 0.9em;

  //   .blue-text {
  //     font-size: 0.9em;
  //   }
  // }
}

.multiline {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  min-height: 17px;
  max-width: 18.3vw;
  padding: 0.05rem 0;

  &:hover {
    -webkit-line-clamp: unset !important;
  }
}

.custom-disable {
  background-color: #ecebeb !important;
}

.search-wrapper {
  background-color: #ffffff;
  min-height: 35px !important;
}

.MuiRadio-root.Mui-checked {
  color: #174baf !important;
}

//manage user
.add-box {
  box-shadow: 0px 3px 20px #00000066;
  border-radius: 0px 0px 18px 18px;
  min-height: 80px;
  z-index: 1;
  // position: absolute;
  // right: 2.5%;
  // top: 76px;
  position: fixed;
  top: 85px;
  right: 33px;
  width: 240px;
  max-height: calc(100vh - 95px);

  .add-inner-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      180deg,
      rgb(0, 0, 0) -13%,
      rgb(74, 74, 74) 85%
    );
    // linear-gradient( rgba(0,0,0,1) 0%, rgba(74,74,74,1) 85%);
    box-shadow: 0px 3px 20px #00000066;
    border-radius: 0 0 18px 18px;
    opacity: 0.95;
    z-index: -1;
  }

  .link-txt {
    color: #4d71f1 !important;
    word-break: break-all;
  }

  .link-underline {
    text-decoration: underline solid #4d71f1;
  }

  .user-dis {
    color: #cccccc !important;
    font-size: 17px !important;
    padding: 1rem 1.2rem;
    line-height: 20px;
  }

  .plus {
    width: 23px;
    height: 24px;
    border-radius: 50%;
    background-color: #d3d4d5;
    color: #4a4a4a !important;
    margin-right: 1rem !important;
    font-size: 21px !important;
  }

  .a-mt-3 {
    font-size: 15px !important;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    transition: 0.5s;
    cursor: pointer;
    border-radius: 0px 0px 7px 7px;

    &:hover {
      background-color: #002666;
    }
  }

  .built-option {
    font-size: 15px !important;
    padding: 1rem 1rem;
    cursor: pointer;
    transition: 0.5s;
    cursor: pointer;
    color: #cccccc !important;

    &:hover {
      background-color: #002666;
    }
  }

  .built-cancel {
    font-size: 15px !important;
    padding: 1rem 1rem;
    cursor: not-allowed !important;
    transition: 0.5s;
    cursor: pointer;
    color: #cccccc !important;

    &:hover {
      background-color: #002666;
    }
  }
  .hover {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .bu-color {
    color: #cccccc !important;
  }
}

.own-popper {
  z-index: 100;
  width: 453px;
  min-height: 100px;
  max-height: 464px;
  border-radius: 20px;
  background-image: linear-gradient(90deg, #262626, #3f4040);
  box-shadow: 0px 3px 20px rgb(0 0 0 / 40%);
  overflow-y: auto;
  top: 100px !important;

  .pop-det-box {
    // min-height: 100px;
    max-height: 400px;
    // overflow-y: auto;
  }

  .pop-title {
    font-size: 17px !important;
    font-family: Arial-Bold !important;
    margin-bottom: 0.5rem;
    color: #cccccc !important;
    padding: 0.5rem 1rem 0 1rem;
    cursor: pointer;

    &.active {
      text-decoration: underline solid #cccccc;
    }
  }

  .pop-dis {
    color: #cccccc !important;
    font-size: 15px !important;
    padding: 10px 1.5rem 0.5rem 1.5rem;
    line-height: 20px !important;
  }
}

.bulk-add-box {
  z-index: 99;
  position: fixed;
  // padding-top: 110px;
  right: 33px;
  top: 71px;
  overflow: auto;
  max-height: calc(100vh - 132px);

  .add-inner:nth-child(3) {
    padding-top: 0.5rem !important;
  }

  .add-inner:nth-child(2),
  .add-inner:nth-child(3),
  .add-inner:nth-child(4),
  .add-inner:nth-child(5) {
    border-radius: 18px !important;

    .add-inner-box {
      border-radius: 18px !important;
      background-image: linear-gradient(180deg, #3f4040 0%, #262626 100%);
    }
  }

  .add-inner {
    position: relative;
    // box-shadow: 0px 3px 20px #00000066;
    border-radius: 0px 0px 18px 18px;
    min-height: 61px;
    width: 240px;
    padding-bottom: 0.6rem;

    .add-inner-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-image: linear-gradient(180deg, #000000 0%, #4a4a4a 100%);
      background-image: linear-gradient(
        180deg,
        rgb(0, 0, 0) -13%,
        rgb(74, 74, 74) 85%
      );
      // box-shadow: 0px 3px 20px #00000066;
      border-radius: 0 0 18px 18px;
      opacity: 0.95;
      z-index: -1;
    }

    .l-y {
      line-height: 20px !important;
    }

    .link-txt {
      color: #4d71f1 !important;
      word-break: break-all;
    }

    .link-underline {
      text-decoration: underline solid #4d71f1;
    }

    .user-dis {
      color: #cccccc !important;
      font-size: 17px !important;
      padding: 1rem 2rem;
      line-height: 20px;
    }

    .plus {
      width: 20px;
      height: 19px;
      border-radius: 50%;
      background-color: #d3d4d5;
      color: #4a4a4a !important;
      margin-right: 0.2rem !important;
      font-size: 16px !important;
    }

    .a-mt-3 {
      font-size: 15px !important;
      padding: 0.5rem 2rem;
      cursor: pointer;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        background-color: #002666;
      }
    }

    .built-option {
      font-size: 15px !important;
      padding: 0.689rem 1rem;
      cursor: pointer;
      transition: 0.5s;
      cursor: pointer;
      color: #cccccc !important;

      &:hover {
        background-color: #002666;
      }
    }

    .disalbed {
      cursor: not-allowed !important;
    }

    .bu-color {
      color: #cccccc !important;
    }
  }

  .a-mt-3 {
    font-size: 15px !important;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    transition: 0.5s;
    cursor: pointer;
    border-radius: 10px 10px 7px 7px;

    &:hover {
      background-color: #002666;
    }
  }
}

.List {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
}

.assem-box {
  background-color: #ffffff;
  border-radius: 0.5rem;

  .as-title {
    padding: 1rem 2rem;
  }

  .assem-head {
    border-bottom: 1px solid #cccccc;
  }

  .as-list {
    padding: 0rem;

    .float-right {
      float: right;
      padding: 0.1rem 0;
    }

    .edit-icon {
      float: right;
      padding: 0.1rem 0;
      height: 100%;
    }

    .as-item {
      margin: 0 0 10px 0;
      min-height: 32px;
      transition: 0.5s;
      cursor: move;

      span {
        font-size: 14px !important;
        padding-left: 1rem;
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      img {
        display: block;
      }

      &:hover {
        background-color: #ffffff !important;
        border-radius: 20px;

        img {
          display: block;
          cursor: pointer;
        }
      }
    }
  }

  .assem-badge {
    color: #017a47;
    background-color: #d3fadb;
    width: auto;
    height: 25px;
    min-width: 80px;
    border-radius: 25px;
    text-transform: capitalize !important;
    padding: 0px 10px !important;
    box-shadow: unset;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: #d7f7e0;
      cursor: unset;
      box-shadow: unset;
    }
  }
}

.as-bg-box {
  min-height: 52px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem;
  position: relative;

  .farrow {
    position: absolute;
    bottom: 15%;
    left: -57px;
  }

  .tarrow {
    position: absolute;
    top: 15%;
    left: -58px;
  }

  .br-14 {
    border-radius: 14px !important;
  }

  .attac-item {
    min-height: 32px;
    background-color: #edeeef;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
  }
}

.assem-drop-box {
  min-height: 40px;
  padding: 16px !important;
  border-top: 1px solid #cccccc;
}

.drop {
  min-height: 32px;
  border: 2px dashed #002c69;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  color: #002c69 !important;
}

.addbox {
  background-color: #f2f2f2;
  border-radius: 8px;
  min-height: 187px;
  padding: 24px;
  margin-top: 3rem !important;
  position: relative;
}

.info-message-text {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding: 8px 8px 0px 8px !important;
}

.custom-modal1 {
  position: relative;
  overflow-y: auto;
  background-color: #fff;
  min-height: 187px;
  padding: 21px 25px 34px;
  margin-top: 3rem !important;
  position: relative;

  .modal-title {
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .m-border {
    padding: 1rem 2rem;
    border-bottom: 1px solid #cccccc;
  }

  .modal-footer {
    padding: 1rem 2rem;

    .mcancel-btn {
      color: #343434;
      opacity: 0.58;
      text-transform: capitalize !important;
    }

    .madd-btn {
      min-width: 131px;
      height: 35px;
      background-color: #002c69;
      border-radius: 6px;
      color: #ffffff !important;
      text-transform: capitalize !important;
    }
  }
}

.title-alignment {
  align-items: end;
}

.own-popper {
  z-index: 1000 !important;
}

.addbox-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0 0.2rem 0;
}

.add-item-mr {
  margin-bottom: 15px;
}

.uadd-title {
  font-family: Arial-Bold !important;
  font-size: 20px !important;
  color: #333333 !important;
  padding: 40px 0 10px 0px;
  letter-spacing: 0 !important;
}
.uadd-title-iframe {
  font-family: Arial-Bold !important;
  font-size: 20px !important;
  color: #333333 !important;
  padding: 5px 0 10px 0px;
  letter-spacing: 0 !important;
}

.MuiIconButton-edgeEnd {
  .MuiSvgIcon-root {
    width: 20px !important;
  }
}

.cp-add-title {
  font-family: Arial-Bold !important;
  font-size: 20px !important;
  color: #333333 !important;
}

.w-title {
  font-family: Arial-Bold !important;
  font-size: 23px !important;
  margin: 10px 0 !important;
}

.steps {
  height: 5px;
  background-color: #c9c9c9;
  border-radius: 5px;

  &.step-active {
    background-color: #002c69 !important;
  }
}

.sticky-tab {
  background: #09171f;
  padding: 15px 20px;
  position: sticky;
  top: 80px;
  z-index: 999;
}

.sticky-tab1 {
  background: #ffff;
  padding: 15px 20px;
  position: sticky;
  top: 80px;
  z-index: 999;
}

.user-photo {
  width: 119px;
  height: 119px;
  border-radius: 50%;
  border: 6px solid #d8d8d8;
  background-color: #f1f1f1;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 44%;
  margin-top: -3rem;
}

.user-poto-box {
  position: absolute;
  top: 0;
  left: 44%;
  margin-top: -3rem;

  .user-poto {
    width: 119px;
    height: 119px;
    border-radius: 50%;
    border: 6px solid #d8d8d8;
    background-color: #f1f1f1;
    object-fit: cover;
  }

  .pencil-box {
    bottom: 8%;
    cursor: pointer;
    position: absolute;
    right: 0%;

    .d-block {
      display: block;
    }

    .d-none {
      display: none;
    }

    transition: 0.4s;

    &:hover {
      .d-block {
        display: none;
      }

      .d-none {
        display: block;
      }
    }
  }
}

.custom-box {
  min-height: 40px;
  border: 1px solid #cccccc;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem 0.2rem 2rem;
  margin-bottom: 1rem !important;

  .edit {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
}

.u-add-in {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 4px;
  min-height: 88px;
  padding: 24px 30px 24px 10px;

  .u-pr-1 {
    padding-right: 10px;
  }

  .u-pl-1 {
    padding-left: 18px;
  }
}

.u-add-in-iframe {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 4px;
  min-height: 88px;
  // padding: 24px 30px 24px 10px;

  .u-pr-1 {
    padding-right: 10px;
  }

  .u-pl-1 {
    padding-left: 18px;
  }
}

.pay-pad {
  .u-pr-1 {
    padding-right: 10px;
  }

  .u-pl-1 {
    padding-left: 18px;
  }
}

.padding-box {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.lead-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cccccc;
  border-radius: 4px;
  min-height: 88px;
  padding: 15px 10px;

  .u-pr-1 {
    padding-right: 10px;
  }

  .u-pl-1 {
    padding-left: 18px;
  }
}

.userBox {
  display: flex;
  flex-wrap: wrap;

  .uname {
    opacity: 0.8;
    font-family: Arial-Bold !important;
    font-size: 15px !important;
    word-break: all;
  }

  .urole {
    opacity: 0.5;
    font-size: 14px !important;
    padding-top: 0.3rem;
  }

  // .userItem:nth-child(4n) {
  //   margin-right: unset !important;
  // }
  .userItem {
    align-items: center;
    border-radius: 10px;
    display: flex;
    min-height: 85px;
    padding: 16px 20px;
    position: relative;
    margin: 0 0rem 15px 15px;
    background-color: rgba(242, 242, 242, 0.15);
  }
}

.configureBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .configureItem {
    align-items: center;
    border-radius: 10px;
    display: flex;
    min-height: 101px;
    padding: 16px 50px;
    position: relative;
    margin: 0 0 10px 0;
    transition: 0.5s;
    background-color: rgba(242, 242, 242, 0.15);

    &:hover {
      box-shadow: 0 0 15px #17c8e7;

      .d-block {
        display: none !important;
      }

      .d-none {
        display: block !important;
      }
    }
  }

  .rl-text {
    color: #bfc2c3 !important;
    opacity: 0.8;
    font-size: 15px !important;
    line-height: 24px !important;
  }

  .blue-text {
    color: #1abfca !important;
    font-family: Arial-Bold !important;
    font-size: 17px !important;
  }
}

.leadBox {
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;

  svg {
    fill: #84898c;
    width: 30px;
    height: 37px;
  }

  .d-flex {
    display: flex;
  }

  .leadItem {
    align-items: center;
    border-radius: 10px;
    display: flex;
    min-height: 101px;
    padding: 1rem;
    position: relative;
    margin: 0 0rem 1rem 0;
    transition: 0.4s;
    cursor: pointer;
    background: rgba(242, 242, 242, 0.15);

    .d-block {
      display: block;
    }

    .d-none {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 15px #17c8e7;

      .d-block {
        display: none !important;
      }

      .d-none {
        display: block !important;
      }
    }
  }

  .itemAffiliate {
    align-items: center;
    border-radius: 10px;
    display: flex;
    min-height: 101px;
    padding: 1rem;
    position: relative;
    margin: 0 0rem 1rem 0;
    transition: 0.4s;
    cursor: not-allowed;
    background: rgba(242, 242, 242, 0.15);

    .d-block {
      display: block;
    }

    .d-none {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 15px #e71755;

      .d-block {
        display: none !important;
      }

      .d-none {
        display: block !important;
      }
    }
  }

  .rl-text {
    color: #bfc2c3 !important;
    opacity: 0.8;
    font-size: 15px !important;
  }

  .blue-text {
    color: #1abfca !important;
    font-family: Arial-Bold !important;
  }
}

.buildedit {
  background-color: #ffffff;
  padding: 2.5rem;
  color: #ffffff !important;

  // height: 70vh;
  // overflow-y: auto;
  p,
  a,
  span,
  label,
  h1,
  h2,
  h3,
  li {
    font-size: 18px !important;
  }

  .o-text {
    color: #343434 !important;
    opacity: 0.8;
    line-height: 34px !important;
  }
}

.strategy {
  .strate-text {
    font-size: 17px !important;
    font-family: Arial-Bold !important;
    cursor: pointer;
  }

  .n-blue-text {
    color: #14dfe6 !important;
    opacity: 0.8;
    font-size: 17px !important;
    font-family: Arial-Regular !important;
    cursor: pointer;
  }
}

.c-p-box {
  min-height: 232px;
  background-color: #ffffff;
  padding: 42px 63px;
  border-radius: 8px;

  .c-blue {
    color: #4d71f1 !important;
    font-size: 16px !important;
    font-family: Arial-regular !important;
    line-height: 22px;
    word-break: break-all;
  }

  .c-noraml {
    color: #000000 !important;
    font-family: Arial-regular !important;
    line-height: 22px;
    word-break: break-all;
    text-align: center;
  }

  .c-p-item {
    padding: 0 2rem;
    min-height: 45px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 30px;
    margin-bottom: 1rem !important;
  }
}

.w-100 {
  width: 100%;
}

.row-radio {
  .MuiFormGroup-root {
    // border-right: 2px solid #cccccc;
    // margin-right: 2rem;
    padding-top: 1.5rem;

    label {
      margin-bottom: 1rem !important;
    }
  }
}

.row-radio-border {
  border-left: 2px solid #cccccc;
  padding-left: 2rem;
}

.contractPayBox {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 12px;
  min-height: 350px;

  .disable-contract {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.4;

    &:hover {
      box-shadow: unset !important;
    }
  }
}

.o-lead-box {
  .disable-lead {
    cursor: not-allowed !important;
    opacity: 0.7;

    &:hover {
      box-shadow: unset !important;
    }
  }

  .leadDetailBox {
    position: relative;
  }

  .x-line {
    position: absolute;
    right: -89px;
    bottom: -66px;
    transform: rotate(70deg);
  }

  .l-line {
    position: absolute;
    left: -92px;
    bottom: -68px;
  }

  .m-64 {
    padding-right: 64px;
  }

  .m-41 {
    margin-bottom: 35px;
  }

  .r-line {
    position: absolute;
    right: -90px;
    bottom: 35%;
  }

  .v-line {
    position: absolute;
    left: 37%;
    bottom: -44px;
    transform: rotate(90deg);
  }
}

.leadDetailBox {
  min-height: 200px;
  background-color: #eaeaea;
  border-radius: 14px;
  padding: 1rem 1rem 0.5rem 1.5rem;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    box-shadow: 0 0 15px#17c8e7;
  }

  .info {
    width: 40px;
    height: 40px;
  }

  .dis-text {
    color: #373737 !important;
    line-height: 22px !important;
  }
}

.upload-ul {
  list-style: none;
  padding: 2rem 0px !important;
  margin: unset !important;

  .udot {
    width: 6px;
    height: 6px;
    background-color: #b8c2df;
    border-radius: 50%;
    margin: 0.4rem 1rem 0 1rem !important;
  }

  .blue-ul {
    min-height: 122px;
    border-radius: 16px;
    background-color: #4d71f1;
    color: #ffffff !important;
    width: 100%;
    padding: 0 0 10px 0 !important;
  }

  .white-ul {
    // min-height: 122px;
    border-radius: 16px;
    background-color: #4d71f1;
    color: #ffffff !important;
    width: 100%;
  }

  .u-btn {
    color: #fff;
    position: absolute;
    height: 30px !important;
    right: 65px;
    top: 15px;
    text-transform: capitalize !important;
    border-radius: 10px;
  }

  .u-btn-outer {
    color: #fff;
    position: absolute;
    height: 30px !important;
    right: 55px;
    top: 0;
    text-transform: capitalize !important;
    border-radius: 10px;
    transform: translate(0%, 38%);
  }

  li {
    padding: 0 1rem;
    position: relative;
    font-size: 17px !important;
    width: 100%;
  }

  ul {
    li {
      padding: 0.5rem 1rem;
      position: relative;
      font-size: 17px !important;
      display: flex;
      width: 100%;
    }
  }

  ul {
    list-style: circle !important;
  }
}

//report
.pay-repo-box {
  .pay-repo-item {
    min-height: 45px;
    border-radius: 10px;
    position: relative;
    padding: 14px 20px;
    margin-bottom: 0.5rem;
    font-size: 14px !important;
    cursor: pointer;

    .inn-repo {
      background-color: #f2f2f2;
      border-radius: 10px;
      opacity: 0.15;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

//status btn
.switch-field {
  display: flex;
  overflow: hidden;
  border-radius: 5px;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
  color: #333333;
  font-size: 14px !important;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  flex: 1;
  height: 35px;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #0ccf6e;
  color: #fff;
  box-shadow: none;
  border-radius: 5px !important;
}

.pointer {
  cursor: pointer;
}

//tab css
.roleTab {
  .MuiBox-root {
    border: unset !important;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      // margin-left: 1.5rem;
      // margin-right: 1rem;
      border-top-left-radius: 5px;
    }

    .MuiTab-root:last-child {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: #002c69 !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      min-width: 215px !important;
      min-height: 45px;
      padding: 7px 16px !important;
      background-color: #555d60 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      // margin-right: 1rem !important;
      font-family: Arial-Bold !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.MuiTabScrollButton-root {
  background-color: #002c69 !important;
  border-radius: 5px !important;

  svg {
    fill: #ffffff !important;
  }
}

.uploadTab {
  .MuiBox-root {
    border: unset !important;
  }

  .MuiTabPanel-root {
    padding: unset !important;
  }

  .f-15 {
    font-size: 15px !important;
  }

  .u-no {
    // padding: 0px 28px 0 50px;
    font-size: 17px !important;
    text-align: center !important;
    width: 80px;
    padding-left: 20px;
  }

  .g-badge {
    font-size: 14px !important;
    min-width: 26px;
    height: 26px;
    background-color: #00bc2d;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 1%;
    top: 21%;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      // margin-left: 1.5rem;
      // margin-right: 1rem;
      border-top-left-radius: 5px;
    }

    .MuiTab-root:last-child {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: #002c69 !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      min-width: 215px !important;
      min-height: 45px;
      padding: 7px 16px !important;
      background-color: #555d60 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      // margin-right: 1rem !important;
      font-family: Arial-Bold !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.cquesTab {
  .MuiBox-root {
    border: unset !important;
  }

  .MuiPaper-elevation {
    margin-bottom: 1rem;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: unset !important;
  }

  .MuiAccordionSummary-root {
    background-color: #21292e30 !important;
    min-height: 40px !important;

    .Mui-expanded {
      min-height: 40px !important;
      display: flex;
      align-items: center;
    }

    p {
      font-size: 15px !important;
      font-family: Arial-Bold !important;
    }
  }

  .MuiAccordionDetails-root {
    background-color: #f2f2f2;
  }

  .MuiTabPanel-root {
    padding: 20px 35px !important;
    background-color: #ffffff;
    border-radius: 8px;
  }

  .f-15 {
    font-size: 15px !important;
  }

  .u-no {
    padding: 0 2rem 0 4rem;
    font-size: 17px !important;
    min-width: 116px;
    text-align: right;
  }

  .g-badge {
    font-size: 14px !important;
    min-width: 26px;
    height: 26px;
    background-color: #00bc2d;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 1%;
    top: 21%;
  }

  .pad-sadow {
    .infinite-scroll-component {
      padding: 6px !important;
    }
  }

  .MuiTabs-flexContainer {
    height: 45px;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      // margin-left: 1.5rem;
      // margin-right: 1rem;
      border-top-left-radius: 5px;
    }

    .MuiTab-root:nth-child(3) {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTab-root:last-child {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: #002c69 !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      min-width: 215px !important;
      min-height: 45px;
      padding: 7px 16px !important;
      background-color: #555d60 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      // margin-right: 1rem !important;
      font-family: Arial-Bold !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.parsin-ceck {
  svg {
    fill: #ffffff !important;
  }
}

.MuiTabs-scrollButtons {
  opacity: 1 !important;

  &.Mui-disabled {
    opacity: 0 !important;
  }

  svg {
    font-size: 28px !important;
  }
}

.wizardTab {
  &.tabWidth {
    .MuiTabs-root {
      width: 85%;
    }
  }

  input,
  textarea {
    cursor: pointer;
  }

  .rodio-pad {
    label {
      margin-bottom: 5px;
    }
  }

  span {
    font-size: 14px;
  }

  .MuiPaper-elevation {
    margin-bottom: 1rem;
  }

  .wizard-datepiker {
    input {
      padding: 7.5px 14px;
      font-size: 14px;
    }

    .error {
      border: 2px solid;
      border-radius: 4px;
      border-color: red;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: unset !important;
  }

  .MuiAccordionSummary-root {
    background-color: #21292e30 !important;
    min-height: 40px !important;

    .Mui-expanded {
      min-height: 40px !important;
      display: flex;
      align-items: center;
    }

    p {
      font-size: 15px !important;
      font-family: Arial-Bold !important;
    }
  }

  .MuiAccordionDetails-root {
    background-color: #f2f2f2;
  }

  .MuiTabPanel-root {
    padding: 20px 24px !important;
    background-color: #ffffff;
    border-radius: 8px;
  }

  .f-15 {
    font-size: 15px !important;
    line-height: 20px;
  }

  .u-no {
    padding: 0 2rem 0 4rem;
    font-size: 17px !important;
    min-width: 116px;
    text-align: right;
  }

  .g-badge {
    font-size: 14px !important;
    min-width: 26px;
    height: 26px;
    background-color: #00bc2d;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 1%;
    top: 21%;
  }

  .pad-sadow {
    .infinite-scroll-component {
      padding: 6px !important;
    }
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:last-child {
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: unset !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      border-radius: 5px;
      min-height: 35px !important;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      // display: -webkit-box;
      // overflow: hidden;
      // text-overflow: ellipsis;
      padding: 5px 16px !important;
      background-color: #c9c9c9 !important;
      font-size: 14px !important;
      color: #ffffff !important;
      margin-right: 1rem !important;
      font-family: Arial-Medium !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Medium !important;
      background-color: #002c69 !important;
    }

    .section-approve {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Medium !important;
      background-color: #35c68a !important;
    }
  }
}

.strick {
  background-color: #f4f9ff;
}

.ph-box2 {
  .ph-item {
    min-height: 80px;
    // border: 1px solid #cccccc;
    background-color: #fff;
    border-radius: 20px;
    padding: 1rem 2rem;
    margin-bottom: 1rem;

    .ablue {
      color: #002c69 !important;
      opacity: 0.8;
      font-size: 15px !important;
      font-weight: 600;
      margin-bottom: 0.3rem !important;
    }
  }
}

.pr-24 {
  padding-right: 24px !important;
}

.infinite-scroll-component {
  padding-right: 5px !important;
}

.react-tel-input .country-list {
  max-height: 160px !important;
}
.contractPaymentTab,
.paylist {
  .ph-box {
    padding: 43px 63px;

    .ph-item {
      min-height: 80px;
      border: 1px solid #cccccc;
      border-radius: 20px;
      padding: 1rem 2rem;
      margin-bottom: 1rem;

      .dblue {
        color: #3357d9 !important;
        font-size: 15px !important;
        font-weight: 500;
        margin-bottom: 0.3rem !important;
      }
    }
  }

  .cp-form {
    padding: 1.5rem 2rem;
    border-bottom: 3px solid #cccccc;

    .pen-text {
      color: #f5282c !important;
      padding-right: 1rem;
    }
  }

  .MuiTabPanel-root {
    padding: unset !important;
    background-color: #ffffff;
  }

  .f-15 {
    font-size: 15px !important;
  }

  .n-blue-text {
    color: #3357d9 !important;
    text-decoration: underline solid #3357d9;
    padding-left: 1rem;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    // .MuiTab-root:first-child {
    //   // margin-left: 1.5rem;
    //   // margin-right: 1rem;
    // }

    .MuiTab-root {
      min-width: 245px !important;
      min-height: 35px !important;
      padding: 7px 16px !important;
      background-color: #ffffff !important;
      font-size: 16px !important;
      color: #333333 !important;
      margin-right: 1rem !important;
      font-family: Arial-Bold !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 14px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.uploadName {
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-left: 1rem !important;
  font-family: Arial-regular !important;
  color: #017a47 !important;
}

.selector {
  background-color: #696565;
  color: #ffffff;
}

.documentTab {
  .MuiTabs-root {
    min-height: 35px !important;
  }

  .MuiTab-root {
    min-width: 118px !important;
    min-height: 35px !important;
    padding: 7px 0px !important;
    background-color: unset !important;
    margin-right: 1rem !important;
    font-size: 18px !important;
    color: #ffffff !important;
    font-family: Arial-Bold !important;
    text-transform: capitalize !important;
  }

  .MuiTabPanel-root {
    padding: 16px 0px !important;
  }

  .Mui-selected {
    font-size: 18px !important;
    color: #ffffff !important;
    font-family: Arial-Bold !important;
    background-color: unset !important;
  }

  .MuiTabs-indicator {
    background-color: #ffffff !important;
  }

  .MuiBox-root {
    border-bottom: unset !important;
  }
}

.Assem-tab {
  .MuiTabs-root {
    min-height: 35px !important;
  }

  .MuiTab-root {
    min-width: 90px !important;
    min-height: 35px !important;
    padding: 14px 16px !important;
    background-color: unset !important;
    font-size: 18px !important;
    color: grey !important;
    font-family: Arial-Regular !important;
    text-transform: capitalize !important;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #cccccc;
  }

  .Mui-selected {
    font-size: 18px !important;
    color: #000000 !important;
    font-family: Arial-bold !important;
    background-color: unset !important;
    font-weight: 400;
  }

  .MuiTabs-indicator {
    background-color: #002c69 !important;
  }

  .MuiBox-root {
    border-bottom: unset !important;
  }

  background-color: #ffffff;
  border-radius: 0.5rem;
}

.contractPayTab {
  .MuiTabs-root {
    min-height: 35px !important;
  }

  .MuiTab-root {
    min-width: 118px !important;
    min-height: 35px !important;
    padding: 7px 0px !important;
    background-color: unset !important;
    margin-right: 25px !important;
    font-size: 18px !important;
    color: #ffffff !important;
    font-family: Arial-Bold !important;
    text-transform: capitalize !important;
  }

  .MuiTabPanel-root {
    padding: 24px 0px 0 0 !important;
  }

  .Mui-selected {
    font-size: 18px !important;
    color: #ffffff !important;
    font-family: Arial-Bold !important;
    background-color: unset !important;
  }

  .MuiTabs-indicator {
    background-color: #ffffff !important;
  }

  .MuiBox-root {
    border-bottom: unset !important;
  }
}

.parsingTab {
  .MuiTabPanel-root {
    background-color: #ffffff !important;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      // margin-left: 1.5rem;
      // margin-right: 1rem;
      border-top-left-radius: 5px;
    }

    .MuiTab-root:last-child {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: #002c69 !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      min-width: 215px !important;
      min-height: 45px;
      padding: 7px 16px !important;
      background-color: #555d60 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      // margin-right: 1rem !important;
      font-family: Arial-Bold !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.asseTab {
  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      margin-left: 1.5rem;
      margin-right: 1rem;
    }

    .MuiTabPanel-root {
      padding: 0px 1rem !important;
    }

    .MuiTab-root {
      min-width: 19px !important;
      min-height: 35px !important;
      padding: 0px !important;
      background-color: unset !important;
      font-size: 15px !important;
      color: #3357d9 !important;
      margin-right: 1rem !important;
      font-family: Arial-Bold !important;
      text-transform: capitalize !important;
    }

    .Mui-selected {
      font-size: 15px !important;
      color: #3357d9 !important;
      font-family: Arial-Bold !important;
      background-color: unset !important;
    }
  }
}

.custom-date-disabled {
  .Mui-disabled {
    background-color: rgb(226 226 226 / 65%) !important;
  }
}

.p-plus-menu {
  width: 444px;
  height: 304px;
  background-color: #3e65ef;
  padding: 1rem 1.5rem;
  overflow-y: auto;

  img {
    cursor: pointer;
  }

  .white {
    color: #fff !important;
    font-family: Arial-Regular !important;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
  }

  .cp-cu-in {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: navajowhite;
    margin-top: 0.5rem;
  }
}

.custom-p-pop {
  .MuiPaper-root {
    left: 36% !important;
  }
}

.mapTab {
  .MuiPaper-root {
    background-color: unset !important;
  }

  .MuiBox-root {
    padding: unset !important;
  }

  .MuiTabs-root {
    min-height: 35px !important;

    .MuiTab-root:first-child {
      // margin-left: 1.5rem;
      // margin-right: 1rem;
      border-top-left-radius: 5px;
    }

    .MuiTab-root:last-child {
      border-top-right-radius: 5px;
      border-right: unset !important;
    }

    .MuiTabs-indicator {
      background-color: #002c69 !important;
    }

    .MuiTab-root {
      border-right: 1px solid #cccccc;
      max-width: 210px !important;
      min-height: 45px;
      padding: 7px 16px !important;
      background-color: #555d60 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      // margin-right: 1rem !important;
      text-transform: capitalize !important;
      font-family: Arial-Bold !important;
    }

    .Mui-selected {
      font-size: 16px !important;
      color: #ffffff !important;
      font-family: Arial-Bold !important;
      background-color: #002c69 !important;
    }
  }
}

.custom-page {
  .MuiToolbar-root {
    p,
    div {
      color: #000 !important;
    }

    svg {
      fill: #000 !important;
    }
  }
}

.build-box {
  min-height: 67px;
  border-radius: 10px;
  position: relative;
  padding-right: 8px;
  background-color: rgba(242, 242, 242, 0.15);

  .sdow-box {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.15;
    border-radius: 10px;
  }

  .tick {
    position: absolute;
    right: 10px;
    bottom: 5px;
  }

  .badge-tick {
    position: absolute;
    right: 10px;
    bottom: 15px;

    .MuiBadge-badge {
      position: absolute;
      right: 10px;
      bottom: 15px;
    }
  }
}

.pe-custom {
  .MuiMenu-paper {
    background-color: unset !important;
    // box-shadow: 0px 0px 5px 5px #2554da !important;
    border-radius: 20px !important;
  }

  .MuiMenu-list {
    padding: unset !important;
  }
}

.pe-tooltip {
  .black-box {
    width: 288px;
    // min-height: 186px;
    /* UI Properties */
    background: #161616 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
    padding: 25px;

    .text {
      color: #ffffff !important;
      word-break: break-all;
    }

    .text-off {
      color: #9a9a9ab3 !important;
      word-break: break-all;
    }

    svg {
      fill: #ffffff !important;
    }
  }

  .blue-box {
    min-height: 128px;
    background: #2356e6 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    align-items: center;
    padding: 3px;

    .text {
      color: #ffffff !important;
      word-break: break-word;
      width: 150px;
    }
  }
}

.upload-drag-drop {
  min-height: 120px;
  width: 450px;
  border: 2.5px dashed #384eb74d;
  border-radius: 4px;
  padding: 25px;
  flex-direction: column;
  margin: 16px 0;

  .f-16 {
    font-size: 16px;
    padding: 16px 0 8px 0;

    span {
      color: #146ae3 !important;
      text-decoration: underline solid #146ae3;
    }
  }

  .f-12 {
    line-height: 15px !important;
    font-size: 12px;
    color: #848080 !important;
    text-align: center;
  }

  .dropzone {
    position: absolute;
    min-height: 140px;
    min-width: 450px;
  }
}

.upload-drag-drop-assemble {
  min-height: 180px;
  width: 500px;
  border: 2.5px dashed #384eb74d;
  border-radius: 4px;
  padding: 25px;
  flex-direction: column;

  .f-16 {
    font-size: 16px;

    span {
      color: #146ae3 !important;
      text-decoration: underline solid #146ae3;
    }
  }

  .f-12 {
    line-height: 15px !important;
    font-size: 12px;
    color: #848080 !important;
    text-align: center;
  }

  .dropzone {
    position: absolute;
    min-height: 180px;
    min-width: 500px;
  }
}

.hideshow {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.5s;
  cursor: pointer;
  font-size: 14px !important;

  &:hover {
    -webkit-line-clamp: unset !important;
  }
}

.ctitle {
  font-family: Arial-Bold !important;
  font-size: 17px !important;
}

.wite-radio {
  .MuiRadio-root.Mui-checked {
    color: #fff !important;
  }
}

.home {
  width: 56px;
  height: 56px;
  border-radius: 10px;
  position: relative;

  svg {
    fill: #ffffff;
  }

  .inner-home {
    width: 56px;
    height: 56px;
    background-color: #ffffff;
    border-radius: 10px;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.home-text {
  min-width: 158px;
  height: 56px;
  border-radius: 10px;
  padding: 0 1rem;
  position: relative;
  margin-left: 1rem !important;

  a {
    z-index: 2;
  }

  .w-28 {
    img {
      width: 28px;
    }
  }

  .text {
    font-size: 21px !important;
    font-family: Arial-Bold !important;
    opacity: 0.8 !important;
    justify-content: center;
  }

  .in-top {
    background-color: #fff;
    height: 30px;
    opacity: 0.1;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
  }

  .inner-box {
    background-color: #222f36;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.MuiToolbar-root {
  p,
  div {
    color: #ffffff !important;
  }

  svg {
    fill: #ffffff !important;
  }
}

.small-screen-btn,
.small-screen-menu,
.s-screen {
  display: none;
}

.p-f {
  position: relative;
  z-index: 1;
}

.p-wtext {
  color: #ffffff !important;
}

.innerBox {
  padding: 1% 10% 1%;
  position: relative;

  .card {
    box-shadow: 0 0 12px -1px #888;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;

    td {
      padding: 0.3rem 0.5rem !important;
    }

    p,
    li {
      font-weight: 500 !important;
      line-height: 23px !important;
      font-size: 18px !important;
    }
  }
}

.doc-field-box {
  padding: 1.5rem;

  .doc-field-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 5px;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: lightgrey;
    }
  }

  .red {
    color: red !important;
  }

  .normal {
    color: black !important;
  }

  .active {
    background-color: lightgrey;
  }
}

.bene {
  position: relative;

  .inb {
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.15;
    border-radius: 10px;
  }
}

.bene-box1 {
  border-radius: 10px 10px 0 0;
  min-height: 211px;
  position: relative;
  padding: 2rem 3rem 1.5rem 3rem;

  .lp-box {
    .lp-item {
      min-width: 225px;
      min-height: 90px;
      padding: 1rem 1.5rem;
      position: relative;
      margin-right: 1rem;
      margin-bottom: 0.5rem;

      .italic {
        font-style: italic;
      }

      .b-text {
        color: #1abfc7 !important;
      }

      .in-item {
        background-color: #f2f2f2;
        position: absolute;
        border-radius: 10px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.15;
      }
    }
  }
}

.bene-box2 {
  border-radius: 10px;
  min-height: 216px;
  position: relative;
  padding: 2rem 3rem 1.5rem 3rem;

  .b-text {
    color: #1abfc7 !important;
  }

  .team-im {
    width: 63px;
    height: 63px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .inb2 {
    background-color: #f2f2f2;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.14;
  }
}

.beneteam-box2 {
  border-radius: 10px;
  min-height: 171px;
  position: relative;
  padding: 30px 60px;
  margin-right: 34px !important;

  .b-text {
    color: #1abfc7 !important;
  }

  .team-im {
    width: 63px;
    height: 63px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .inb2 {
    background-color: #f2f2f2;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.14;
  }
}

.note-box2 {
  border-radius: 10px;
  min-height: 101px;
  position: relative;
  padding: 25px 49px;
  // margin-right: 34px !important;
  margin-bottom: 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  l .b-text {
    color: #1abfc7 !important;
  }

  .rl-text {
    color: #bfc2c3 !important;
    opacity: 0.8;
    font-size: 15px !important;
    padding-top: 12px;
  }

  .inb2 {
    background-color: #f2f2f2;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.14;
  }
}

.react-dropdown-select-input {
  margin-left: unset !important;
  padding-left: 1px !important;
  border-left: 1px solid lightgrey !important;
}

.react-dropdown-select {
  border-radius: 4px !important;
}

.react-dropdown-select-content {
  span {
    height: 18px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;
  }
}

.new-searc {
  .react-dropdown-select {
    background-color: rgb(132 137 140) !important;
    border: unset;
  }

  .react-dropdown-select-input::placeholder {
    color: #cccccc !important;
    font-size: 15px !important;
  }
}

.cin-box {
  background-color: #f2f2f2;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.14;
}

.usics-box2 {
  border-radius: 10px;
  min-height: 85px;
  position: relative;
  padding: 23px 40px;
  margin-right: 34px !important;

  .b-text {
    color: #1abfc7 !important;
  }

  .team-im {
    width: 63px;
    height: 63px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .inb2 {
    background-color: #f2f2f2;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.14;
  }
}

.custom-input-red {
  height: 35px !important;
  width: 100%;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: unset !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
    border-width: 2px !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7px 14px !important;
  }
}

.custom-input-green {
  height: 35px !important;
  width: 100%;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;

  .MuiOutlinedInput-notchedOutline {
    border-color: green !important;
    border-width: 2px !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7px 14px !important;
  }
}

.custom-textarea-red {
  width: 100%;

  .MuiOutlinedInput-root {
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    padding: 7px 14px;
    font-size: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
    border-width: 2px !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
  }
}

.custom-textarea-green {
  width: 100%;

  .MuiOutlinedInput-root {
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: 5px !important;
    padding: 7px 14px;
    font-size: 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: green !important;
    border-width: 2px !important;
  }

  input {
    color: #333333 !important;
    font-size: 14px !important;
    padding: 7.5px 14px !important;
  }
}

.parsed_highlight.active {
  // border: 2px solid #4f72f1;
  cursor: pointer;
  color: #4f72f1;
  font-size: 18px !important;
  font-family: 'Arial-Regular' !important;
  padding: 4px 2px;
  min-height: 38px;
}

.parsed_highlight.inactive {
  // border: 1px solid red;
  cursor: pointer;
  color: red;
  font-size: 18px !important;
  font-family: 'Arial-Regular' !important;
  padding: 4px 2px;
  min-height: 38px;
}

.parsed_highlight_disabled {
  background: rgba(177, 177, 177, 0.301);
  cursor: not-allowed;
}

.parsed_highlight.inactive-selected {
  border: 2px solid red;
  background: bisque;
  border-radius: 5px;
}

.parsed_highlight.active-selected {
  border: 2px solid #4f72f1;
  background: lavender;
  border-radius: 5px;
}

.txt-color {
  font-size: 12px !important;
  color: #174baf !important;
  padding: 5px;
}

// #add-question {
//   height: 500px;
//   overflow-y: auto;
// }

.default-scroll-box {
  height: 500px;
  overflow-y: auto;
}

.doctype input[type='text']:focus,
.doctype input[type='number']:focus,
textarea.doctype:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  // padding: 3px 0px 3px 3px;
  // margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}

.inner-desc-link a {
  color: #389bff;
}

.pay-method {
  width: 100%;
  justify-content: space-evenly;
}
.goog-te-banner-frame.skiptranslate {
  display: none!important;
}
.goog-te-gadget {
  color: transparent!important;
  font-size: 0!important;
  display: inline-block;
  font-size: 14px;
  font-family:'Arial-Regular' !important;
  font-weight: bold;
  line-height: 20px;
  margin: 0 5px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  .goog-te-combo {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family:'Arial-Regular' !important;
    background-color: #343536;
    border: 5px solid #343536;
    border-radius: 2px;
    box-shadow: 0px 0px 0px 2px #fff;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: block!important;
    font-weight: 300px;
    line-height: 30px;
    min-width: 100px;
    overflow: hidden;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: transform;
    z-index: 1;
  }
}
// .goog-logo-link,.gskiptranslate,.goog-te-gadget span,.goog-te-banner-frame,#goog-gt-tt, .goog-te-balloon-frame,div#goog-gt-{
//   display: none !important;
// }


@media (width: 320px) {
  .temp {
    padding: 15px !important;
  }
}

@media (width: 1514.44px) {
  .total-box {
    .total-item {
      max-width: 214px !important;
    }
  }
}

@media (max-width: 1100px) {
  .small-screen-btn {
    display: block;
  }

  .small-screen-menu {
    display: block;

    .nav-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 50vh;
      overflow-y: auto;
      padding: 1rem 0;
    }

    .nav-item {
      font-size: 22px !important;
      font-family: Arial-Bold !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-active {
      border-bottom: 3px solid #000000;

      a {
        text-decoration: unset !important;
        color: #000000 !important;
      }
    }

    a.item-active {
      color: #000000;
    }

    a.item-inactive {
      color: #000000;
    }
  }

  .s-screen {
    display: block !important;
  }

  .b-screen {
    display: none !important;
  }
}

@media (max-width: 1279px) {
  .leadDetailBox {
    .ob-star,
    .or-star,
    .ot-star,
    .sl-star,
    .sr-star,
    .l-line,
    .r-line,
    .x-line,
    .v-line {
      display: none;
    }
  }

  .o-lead-box {
    flex-direction: column !important;

    .leadDetailBox {
      min-height: 150px !important;
    }

    .MuiGrid-grid-md-4 {
      flex: 1 !important;
      max-width: 100% !important;
    }
  }

  .s-mp {
    padding: unset !important;
    margin-bottom: 1rem !important;
  }

  .st-1 {
    margin-top: 1rem !important;
  }

  .m-64 {
    padding-right: unset !important;
  }

  .m-41 {
    margin-bottom: unset !important;
  }
}
div#tiff-inner-container {
  display: flex;
  justify-content: center;
}

@media (max-width: 1186px) {
  .ov-logo {
    width: 120px;
  }

  .oviebox {
    width: 200px;
  }

  .p-image {
    width: 40px !important;
    height: 40px !important;
  }

  .home35,
  .home {
    width: 35px;
    height: 35px;
  }

  .home-text {
    height: 35px;
    min-width: 100px;

    .in-top {
      height: 20px;
    }

    .text {
      font-size: 14px !important;

      img {
        width: 20px !important;
      }
    }
  }

  .s-mp {
    padding: unset !important;
    margin-bottom: 1rem !important;
  }

  .st-1 {
    margin-top: 1rem !important;
  }

  .mainpadding {
    padding: 6rem 1rem 1rem 1rem !important;
  }

  .cp-box,
  .l-footer {
    flex-direction: column;
  }

  .u-pr-1,
  .u-pl-1 {
    padding: unset !important;
  }

  form {
    .pr,
    .pl {
      padding: unset !important;
    }
  }

  .bulk-add-box {
    .add-inner {
      width: auto !important;
    }
  }
}

@media (min-width: 1187px) and (max-width: 1279px) {
  .oviebox {
    // width: 275px;
    height: 60px;
  }
  .main-container .black-white-head .p-image {
    width: 44px !important;
    height: 44px !important;
  }
}

@media (min-width: 900px) and (max-width: 1279px) {
  .pe-box {
    .MuiGrid-grid-md-3 {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
}

@media (min-width: 1187px) and (max-width: 1321px) {
  .cp-box {
    .cp-flex-4 {
      flex: 2 !important;
    }
  }
}

@media (max-width: 1366px) {
  .home-text {
    .text {
      font-size: 13px !important;
    }
  }
  .main-container .black-white-head .nav-item {
    font-size: 16px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1279px) {
  .navwidth {
    width: 180px !important;

    .ov-logo {
      width: 80px !important;
    }
  }
}

@media (min-width: 1366px) {
  .total-box {
    .total-item {
      max-width: 225px !important;
    }
  }

  .w-450 {
    width: 450px;
  }
}

.full-screen-searc {
  padding-right: 5px !important;
}

.submis-head {
  height: 76px;
  background-color: #031834;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px 0 40px;

  .s1-title {
    color: #ffffff;
    opacity: 0.8;
    font-size: 20px;
  }
}

.c-modal-box {
  .MuiPaper-root {
    width: 447px;
    max-height: 90vh;
    overflow-y: auto;
    min-height: 375px;
    padding: 30px 40px;

    // .custom-textarea {
    //   background-color: #ffffff;
    //   border: 1px solid #cccccc;
    //   border-radius: 4px;
    //   padding: 11px 15px;
    //   width: 100%;
    // }
    // .custom-input {
    //   background-color: #ffffff;
    //   border: 1px solid #cccccc;
    //   border-radius: 4px;
    //   padding: 11px 15px;
    //   width: 100%;
    // }
    .f-18 {
      font-size: 18px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.recomment-modal-box {
  .recom-box {
    min-height: 44px;
    font-size: 18px;
    color: #ffffff !important;
    background-color: #676767;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: relative;
    padding-left: 75px;

    .im {
      position: absolute;
      left: -25px;
    }
  }

  .f-c {
    color: #848080 !important;
  }

  .info-box {
    height: 247px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    overflow-y: auto;
    border-radius: 4px;
    padding: 13px;
    color: #848080 !important;
  }

  .MuiPaper-root {
    width: 800px;
    max-width: unset !important;
    max-height: 90vh;
    overflow-y: auto;
    min-height: 375px;
    padding: 30px 40px;

    .custom-textarea {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 11px 15px;
      width: 100%;
    }

    .custom-input {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 11px 15px;
      width: 100%;
    }

    .f-18 {
      font-size: 18px;
    }

    svg {
      cursor: pointer;
    }
  }
}

.upload-body {
  height: calc(100vh - 158px);
  padding: 45px 40px 15px 40px;
  overflow-y: auto;

  // .custom-textarea {
  //   background-color: #ffffff;
  //   border: 1px solid #cccccc;
  //   border-radius: 4px;
  //   padding: 11px 15px;
  //   width: 100%;
  // }
  // .custom-input {
  //   background-color: #ffffff;
  //   border: 1px solid #cccccc;
  //   border-radius: 4px;
  //   padding: 11px 15px;
  //   width: 100%;
  // }
  .MuiPaper-root {
    margin-bottom: 28px;
    border: unset !important;
  }

  .f-19 {
    font-size: 12px;
    color: #333333 !important;
    padding-left: 8px;
  }

  .f-9 {
    font-size: 9px;
  }

  .MuiAccordionDetails-root {
    padding-top: 0px !important;
  }

  .issue-table {
    width: auto !important;
    min-width: 0px !important;

    thead {
      th {
        background-color: #e9f1ff;
        font-size: 12px !important;
        padding: 4px 10px !important;
      }
    }

    tbody {
      td {
        background-color: #f6f6f6;
        color: #333333 !important;
        line-height: 18px !important;
        font-size: 12px !important;
        border-width: 3px !important;
        padding: 4px 10px;
      }

      .accept {
        color: #21c703 !important;
        font-size: 9px !important;
        font-weight: 600;
      }

      .pending {
        color: #f5282c !important;
        font-size: 9px !important;
        font-weight: 600;
      }
    }
  }

  .issue-top {
    height: 60px;
    background-color: #022658;
    align-items: center;
    display: flex;
    font-size: 15px;
    color: #fff !important;
    padding: 0 18px;
  }

  .issue-box {
    // height: 600px;
    height: 70vh;
    // overflow-y: auto;
    background-color: #fff;
    padding: 20px;

    .MuiAccordionSummary-root {
      min-height: 25px !important;
      background-color: #cccccc !important;
    }
  }

  .center-upload {
    width: 540px;
    min-height: 500px;
    /* UI Properties */
    background-color: #ffffff;
    border-radius: 4px;
    margin: auto;
    padding: 40px 40px 30px 40px;

    .required {
      color: #f5282c !important;
    }

    .custom-textarea {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 11px 15px;
      width: 100%;
    }

    .uinfo-box {
      min-height: 35px;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      padding: 11px 15px;

      .intext {
        flex: 1;
        font-size: 11px !important;
        word-break: break-all;
      }

      .w-16 {
        width: 16px;
        height: 16px;

        img {
          cursor: pointer;
        }
      }
    }

    .upload-div {
      min-height: 165px;
      border: 2px dashed #384eb74d;
      border-radius: 4px;
      padding: 25px;
      flex-direction: column;
      margin: 16px 0;

      .f-16 {
        font-size: 16px;
        padding: 16px 0 8px 0;

        span {
          color: #146ae3 !important;
          text-decoration: underline solid #146ae3;
        }
      }

      .f-12 {
        line-height: 15px !important;
        font-size: 12px;
        color: #848080 !important;
        text-align: center;
      }

      .dropzone {
        position: absolute;
        min-height: 140px;
        min-width: 450px;
      }
    }
  }
}

.rfe-footer {
  background-color: #f6f6f6;

  div,
  a,
  span {
    color: #333333 !important;
  }
}

.submis-body {
  height: calc(100vh - 76px);
  padding: 45px 98px 15px 98px;
  overflow-y: auto;

  .MuiPaper-root {
    margin-bottom: 28px;
    border: unset !important;
  }

  .issue-table {
    width: auto !important;
    min-width: 0px !important;

    thead {
      th {
        background-color: #e9f1ff;
        padding: 10px !important;
        font-size: 16px !important;
      }
    }

    tbody {
      td {
        background-color: #f6f6f6;
        color: #333333 !important;
        line-height: 18px !important;
        font-size: 16px !important;
        border-width: 3px !important;
      }

      .accept {
        color: #21c703 !important;
        font-size: 16px !important;
        font-weight: 600;
      }

      .pending {
        color: #f5282c !important;
        font-size: 16px !important;
        font-weight: 600;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .sub-title {
    text-align: right;
    color: #146ae3 !important;
    font-size: 19px;
    margin-bottom: 34px;
  }

  .f-19 {
    font-size: 19px;
    color: #333333 !important;
    padding-left: 28px;
  }

  .mr-3 {
    margin-right: 40px;
  }
}

.react-swipeable-view-container {
  margin-right: -2px !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 250px;
}

@media (min-width: 1280px) {
  .minmax-paybox {
    width: 1000px;
  }

  .suc-dis {
    width: 550px;
  }

  .pay-end-box {
    justify-content: flex-end;

    .summary-box {
      width: 321px;
    }
  }
}

@media (max-width: 1280px) {
  .bulk-add-box {
    top: unset !important;
    right: unset !important;
  }

  .full-screen-searc {
    justify-content: unset !important;
  }

  .small-search {
    width: 100%;
    justify-content: flex-end !important;

    .searc-box1,
    .searc-box2,
    .searc-box3 {
      width: 22% !important;
      border-bottom: 1px solid lightgrey;
    }

    .searc-box3 {
      border-bottom: unset !important;
    }
  }
}

@media (min-height: 649px) {
  .logout-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .stick-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (min-height: 768px) {
  .loginstick-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 1366px) {
  .bulk-add-box {
    right: unset !important;
    // .add-inner {
    //   width: auto !important;
    // }
  }
}

.line-20 {
  line-height: 20px !important;
}

::-ms-reveal {
  display: none !important;
}

.reset-button {
  background-color: #002c69;
  border: unset;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-family: Arial-Regular;
  font-size: 15px;
  height: 28px;
  margin-left: 5px;
  padding-left: 13px;
  padding-top: 6px;
  position: relative;
  width: 70px;
}

.back-btn {
  min-width: 120px !important;
  border-radius: 5px !important;
  height: 40px !important;
  font-size: 16px !important;
  font-family: Arial-Bold !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  position: relative;
  background-color: #bcc7dc !important;
}
