:host {
    width: 100%;
    max-height: 100%;
}

.fundo {
    animation: scales 3s alternate infinite;
    transform-origin: center;
}

.pao-baixo {
    animation: rotatepao 14s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
    transform-origin: center;
}

.pao-cima {
    animation: rotatepao 7s 1s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
    transform-origin: center;
}

.olhos {
    animation: olhos 2s alternate infinite;
    transform-origin: center;
}

.left-sparks {
    animation: left-sparks 4s alternate infinite;
    transform-origin: 150px 156px;
}

.right-sparks {
    animation: left-sparks 4s alternate infinite;
    transform-origin: 310px 150px;
}

.olhos {
    animation: olhos 2s alternate infinite;
    transform-origin: center;
}

@keyframes scales {
    from {
        transform: scale(0.98);
    }

    to {
        transform: scale(1);
    }
}

@keyframes rotatepao {
    0% {
        transform: rotate(0deg);
    }

    50%,
    60% {
        transform: rotate(-20deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes olhos {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(30deg);
    }
}

@keyframes left-sparks {
    0% {
        opacity: 0;
    }
}

.main {
    min-height: 340px;
    margin: 0px auto;
    width: auto;
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
    min-width: 350px;

}

.path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: dash 4s alternate infinite;
}

@keyframes dash {

    0%,
    30% {
        fill: 4b4b62;
        stroke-dashoffset: 0;
    }

    80%,
    100% {
        fill: transparent;
        stroke-dashoffset: -200;
    }
}


.container {
    width: 550px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: #fff;
    padding: 40px 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    min-height: 180px;

    @media only screen and (max-device-width: 768px) and (min-device-width: 320px) {
        width: 380px;
        height: auto;
        padding: 20px;
        // box-shadow: 0 13px 20px rgba(0, 0, 0, .06);
        min-height: 180px;
    }
}

.error-container {
    min-height: 150px;
    border-radius: 8px;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 12px;
    width: 100%;
    min-width: 350px;

    .err-head {
        padding-bottom: 15px;
        text-align: center;
        font-size: 30px !important;
    }

    .err-msg {
        padding: 14px;
        text-align: center;
        line-height: 23px;
    }

    .err-nav-link {
        padding-top: 10px;
    }

    @media only screen and (max-device-width: 768px) and (min-device-width: 320px) {
        height: auto;
        padding: 12px;
        box-shadow: 0 13px 20px rgba(0, 0, 0, .06);
        min-height: 180px;
    }
}

.f-28 {
    font-size: 28px !important;
}