/* add css module styles here (optional) */

._1Lxpd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}

._35l6Q {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._35l6Q ._1oXyH {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 
canvas {
  max-width: 1024px;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  max-height: 700px;
  -o-object-fit: contain;
  object-fit: contain;
}

canvas:hover {
  cursor: pointer;
} */

._2hTXI {
  padding: 0.4rem 2rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #141414;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

._2hTXI:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

._2yRPI {
  margin: 0 16px;
  font-size: 1rem;
  font-weight: 500;
  color: #aaa;
  width: 100px;
  display: inline-block;
  text-align: center;
}

._Tn-iv button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #141414;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  z-index: 99;
  padding: 5px 12px;
}

._Tn-iv button:nth-child(1) {
  left: 5%;
}

._Tn-iv button:nth-child(2) {
  right: 5%;
}

button {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) all;
}

/* button:not(:disabled):hover {
  background-color: #000 !important;
  opacity: 0.8 !important;
} */

._2hsM7 {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 99;
  border: none;
  color: #141414;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 12px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

._2hsM7:hover {
  color: #fff;
}

._2hsM7 svg {
  width: 20px;
  height: 20px;
}
