.draggable {
  display: flex;
  align-items: center;
  margin: 0px 1px 12px 5px;
}

.draggable-category {
  display: flex;
  align-items: flex-start;
  margin: 1rem;
}

h2 {
  margin: 0;
}

.drag-handle {}

.category-container {
  width: 96%;
  padding: 0px 16px 0px 1px;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imgs {
  width: 16px !important;
}

.wb {
  /* word-break: break-word; */
  padding-right: 24px;
}

.inner-child-place {}

.individual-list {
  background-color: #f4f9ff !important;
  border-radius: 13px;
}

.parent-name-bold {
  display: contents;
}

.child-name {
  font-size: 14px;
  color: #000000b5;
  display: contents;
}

.widget-height {
  height: calc(100vh - 370px);
  overflow: scroll;
  overflow-x: hidden;
}

.mt-15 {
  margin-top: 15px !important;
}

.parent-head {
  padding: 12px 0px 18px 10px;
  padding-left: unset;
}

.w-40 {
  width: 40px;
}

.w-65 {
  width: 65px;
}

.drop-parent {
  border: 2px dashed #002c69 !important;
  border-radius: 5px;
  color: #002c69 !important;
  min-height: 32px;
  padding: 0.5rem 1rem;
}

.text-wrap {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 280px;
  padding-left: 8px;
}